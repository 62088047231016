.mechanic-section {
  background-color: #1a1a1a;
  border-radius: 12px;
  padding: 20px;
  margin: 15px 0;
  color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  border: 2px solid #F5130D;
  font-size: 0.9em;
}

.mechanic-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #F5130D;
  padding-bottom: 10px;
}

.header-controls {
  display: flex;
  gap: 10px;
  align-items: center;
}

.mechanic-header h3 {
  font-size: 1.2em;
  color: #F5130D;
  text-shadow: 0 0 10px rgba(245, 19, 13, 0.3);
  margin: 0;
}

.toggle-hire-btn {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-family: 'Press Start 2P', sans-serif;
  font-size: 0.8em;
}

.toggle-hire-btn:hover {
  color: #F5130D;
}

.section-title {
  margin-bottom: 15px;
  border-bottom: 1px solid #333;
  padding-bottom: 5px;
}

.section-title h4 {
  color: #F5130D;
  font-size: 1em;
  margin: 0;
}

/* Seção do mecânico ativo */
.active-mechanic {
  background: linear-gradient(145deg, #1a1a1a, #222);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid rgba(245, 19, 13, 0.3);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.mechanic-info {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.mechanic-profile {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background: rgba(245, 19, 13, 0.05);
  border-radius: 12px;
  border: 1px solid rgba(245, 19, 13, 0.3);
  position: relative;
  overflow: hidden;
}

.mechanic-profile::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, transparent, rgba(245, 19, 13, 0.1), transparent);
  animation: shine 2s infinite;
}

@keyframes shine {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

.mechanic-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-top: 20px;
  background: rgba(245, 19, 13, 0.05);
  padding: 10px;
  border-radius: 6px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid rgba(245, 19, 13, 0.1);
}

.stat-card {
  background: rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 8px;
  border: 1px solid rgba(245, 19, 13, 0.2);
  display: flex;
  align-items: center;
  gap: 10px;
}

.stat-icon {
  font-size: 1.2em;
  color: #F5130D;
}

.stat-info {
  flex: 1;
}

.stat-label {
  font-size: 0.8em;
  color: #999;
  margin-bottom: 4px;
}

.stat-value {
  font-size: 1em;
  color: #fff;
}

/* Seção de carros em reparo */
.cars-in-repair-details {
  margin-top: 15px;
}

.cars-in-repair-details summary {
  cursor: pointer;
  color: #F5130D;
  font-size: 0.9em;
  padding: 5px 0;
}

.cars-in-repair {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-top: 15px;
}

.repair-progress {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
  position: relative;
}

.repair-progress h4 {
  font-size: 0.9em;
  margin: 0 0 10px 0;
  color: #fff;
}

.remove-car-btn {
  background-color: #ff4444;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
  margin-top: 10px;
  min-width: 180px;
}

.remove-car-btn:hover:not(:disabled) {
  background-color: #cc0000;
}

.remove-car-btn:disabled {
  background-color: #666;
  cursor: not-allowed;
  opacity: 0.8;
}

.remove-car-btn:disabled {
  background-color: #444;
  border: 1px solid #666;
  color: #ccc;
}

.progress-bar {
  height: 20px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;
}

.progress {
  height: 100%;
  background: linear-gradient(90deg, #4CAF50, #8BC34A);
  transition: width 0.3s ease;
}

/* Seção de contratação */
.hire-mechanic {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 15px;
}

.duration-selector {
  text-align: center;
  margin: 20px 0;
  font-size: 0.85em;
  background: rgba(245, 19, 13, 0.05);
  padding: 15px;
  border-radius: 8px;
  border: 1px solid rgba(245, 19, 13, 0.2);
}

.duration-selector select {
  background: #1a1a1a;
  color: white;
  border: 1px solid rgba(245, 19, 13, 0.3);
  padding: 8px 15px;
  border-radius: 4px;
  margin-left: 10px;
  font-size: 0.9em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.duration-selector select:hover {
  border-color: #F5130D;
  box-shadow: 0 0 10px rgba(245, 19, 13, 0.2);
}

/* Cards dos mecânicos */
.mechanic-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  padding: 10px;
}

.mechanic-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid #F5130D;
  box-shadow: 0 0 15px rgba(245, 19, 13, 0.3);
  object-fit: cover;
  background: #1a1a1a;
}

.mechanic-option {
  background: linear-gradient(145deg, #1a1a1a, #222);
  border-radius: 12px;
  padding: 20px;
  border: 2px solid rgba(245, 19, 13, 0.3);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  min-height: 450px;
  justify-content: space-between;
}

.mechanic-option:hover {
  border-color: #F5130D;
  transform: translateY(-2px);
  box-shadow: 0 10px 30px rgba(245, 19, 13, 0.3);
}

.mechanic-option-image {
  margin-bottom: 15px;
  position: relative;
}

.mechanic-option-image::after {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border-radius: 50%;
  background: linear-gradient(45deg, #F5130D, transparent);
  z-index: -1;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.mechanic-option:hover .mechanic-option-image::after {
  opacity: 1;
}

.mechanic-option-header {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.mechanic-option-header h4 {
  font-size: 0.95em;
  color: #F5130D;
  margin: 0 0 5px 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}

.mechanic-description {
  color: #999;
  font-size: 0.8em;
  margin: 10px 0;
  line-height: 1.4;
  height: auto;
  max-width: 200px;
  margin: 10px auto;
}

.mechanic-features {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 15px 0;
  text-align: left;
  padding: 0 10px;
  margin-bottom: 15px !important;
  border-bottom: 1px solid rgba(245, 19, 13, 0.1);
  padding-bottom: 15px !important;
}

.feature {
  color: #ccc;
  font-size: 0.75em;
  display: flex;
  align-items: center;
  gap: 5px;
}

.feature span {
  color: #F5130D;
  margin-right: 5px;
}

.daily-cost {
  display: inline-block;
  color: #fff;
  font-size: 0.75em;
  background: rgba(245, 19, 13, 0.2);
  padding: 5px 10px;
  border-radius: 20px;
  border: 1px solid rgba(245, 19, 13, 0.3);
  margin: 15px 0;
  font-weight: bold;
}

.mechanic-details {
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 8px;
  margin: 15px 0;
  text-align: left;
}

.mechanic-details p {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.75em;
  line-height: 1.6;
  padding: 8px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.mechanic-details p span:first-child {
  color: #999;
  font-weight: 500;
}

.mechanic-details p span:last-child {
  color: #fff;
  font-weight: bold;
  background: rgba(245, 19, 13, 0.1);
  padding: 2px 8px;
  border-radius: 4px;
  min-width: 60px;
  text-align: center;
}

.total-cost {
  font-size: 0.85em;
  color: #F5130D;
  text-align: center !important;
  padding: 8px;
  background: rgba(245, 19, 13, 0.15) !important;
  border-radius: 6px;
  margin: 15px 0 !important;
  font-weight: bold;
  border: 1px solid rgba(245, 19, 13, 0.2) !important;
}

.mechanic-action-buttons {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
}

.fire-mechanic-btn,
.extend-contract-btn {
  width: 150px;
  background: linear-gradient(145deg, #F5130D, #ff4444);
  color: white;
  border: 2px solid #c0392b;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 13px;
  font-weight: bold;
  box-shadow: 0 4px #7d240f;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.fire-mechanic-btn:hover,
.extend-contract-btn:hover {
  background: linear-gradient(145deg, #ff4444, #F5130D);
  box-shadow: 0 6px #c0392b;
  transform: scale(1.05);
}

.fire-mechanic-btn:active,
.extend-contract-btn:active {
  box-shadow: 0 2px #d14727;
  transform: translateY(2px);
}

.fire-mechanic-btn:disabled,
.extend-contract-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  background-color: #666;
  border-color: #444;
  box-shadow: none;
}

/* Animações */
@keyframes wrenchRotate {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-15deg); }
  75% { transform: rotate(15deg); }
  100% { transform: rotate(0deg); }
}

.mechanic-header h3 .emoji {
  display: inline-block;
  animation: wrenchRotate 2s infinite;
  font-size: 1.1em;
}

.mechanic-warning {
  background: rgba(245, 19, 13, 0.1);
  border: 1px solid rgba(245, 19, 13, 0.3);
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  color: #F5130D;
  font-size: 0.9em;
  margin: 15px 0;
}

.auto-repair-settings {
  background: rgba(245, 19, 13, 0.05);
  border-radius: 12px;
  padding: 20px;
  margin-top: 20px;
  border: 1px solid rgba(245, 19, 13, 0.3);
}

.auto-repair-settings h4 {
  color: #F5130D;
  margin: 0 0 15px 0;
  font-size: 1.1em;
}

.auto-repair-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333;
  transition: .4s;
  border-radius: 34px;
  border: 2px solid rgba(245, 19, 13, 0.3);
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: #F5130D;
}

input:checked + .toggle-slider:before {
  transform: translateX(26px);
}

.rarity-toggles {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.rarity-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.rarity-label {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
}

.rarity-label.common { background: rgba(150, 150, 150, 0.2); }
.rarity-label.uncommon { background: rgba(75, 175, 75, 0.2); }
.rarity-label.rare { background: rgba(0, 112, 255, 0.2); }
.rarity-label.epic { background: rgba(163, 53, 238, 0.2); }
.rarity-label.legendary { background: rgba(255, 215, 0, 0.2); }
.rarity-label.mythic { background: rgba(255, 0, 255, 0.2); }
.rarity-label.exotic { background: rgba(255, 128, 0, 0.2); }
.rarity-label.ultimate { background: rgba(255, 0, 0, 0.2); }

/* Status Toggles */
.status-toggles {
  margin-top: 20px;
  padding: 15px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.status-toggles h5 {
  color: #F5130D;
  margin: 0 0 15px 0;
  font-size: 0.9em;
}

.status-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.status-label {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
}

.status-label.ok { background: rgba(75, 175, 75, 0.2); }
.status-label.needs_repair { background: rgba(255, 165, 0, 0.2); }
.status-label.broken { background: rgba(255, 0, 0, 0.2); }

/* Fix para o botão de toggle mechanics */
.toggle-mechanics-btn {
  background: transparent;
  border: none;
  color: #F5130D;
  cursor: pointer;
  font-family: inherit;
  font-size: 0.9em;
  padding: 5px 10px;
  transition: all 0.3s ease;
}

.toggle-mechanics-btn:hover {
  color: #ff4444;
  text-shadow: 0 0 5px rgba(245, 19, 13, 0.5);
}

.mechanic-stats p span:last-child {
  color: #fff;
  font-weight: bold;
  background: rgba(245, 19, 13, 0.1);
  padding: 2px 8px;
  border-radius: 4px;
  min-width: 60px;
  text-align: center;
}

.contract-end {
  margin-top: 10px !important;
  padding-top: 10px !important;
  border-top: 1px solid rgba(245, 19, 13, 0.2);
}

.expiration-time {
  font-family: monospace;
  letter-spacing: 0.5px;
  font-size: 0.9em;
  background: rgba(245, 19, 13, 0.2) !important;
}

.mechanic-controls {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

.mechanic-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
  max-width: 300px;
}

.contract-extension {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: rgba(245, 19, 13, 0.05);
  border: 1px solid rgba(245, 19, 13, 0.2);
  border-radius: 8px;
  padding: 15px;
}

.extension-slider {
  background: rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 8px;
}

.days-slider {
  width: 100%;
  height: 4px;
  -webkit-appearance: none;
  background: #333;
  border-radius: 2px;
  outline: none;
}

.days-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background: #F5130D;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.days-slider::-webkit-slider-thumb:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(245, 19, 13, 0.4);
}

.extension-info {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 0.85em;
  background: rgba(0, 0, 0, 0.2);
  padding: 8px;
  border-radius: 4px;
}

.extension-cost {
  color: #F5130D;
  font-weight: bold;
  background: rgba(245, 19, 13, 0.1);
  padding: 2px 8px;
  border-radius: 4px;
}

.confirm-extend-button {
  background: linear-gradient(145deg, #F5130D, #ff4444);
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.hire-button {
  width: 100%;
  padding: 12px;
  background: linear-gradient(145deg, #F5130D, #ff4444);
  border: 2px solid #c0392b;
  border-radius: 8px;
  color: white;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  margin: 15px auto;
  display: block;
  max-width: 250px;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px #7d240f;
}

.hire-button:hover:not(:disabled) {
  background: linear-gradient(145deg, #ff4444, #F5130D);
  transform: scale(1.05);
  box-shadow: 0 6px #c0392b;
}

.hire-button:active {
  box-shadow: 0 2px #d14727;
  transform: translateY(2px);
}

.hire-button:disabled {
  background: #666;
  cursor: not-allowed;
  opacity: 0.7;
  border-color: #444;
  box-shadow: none;
}

.auto-repair-warning {
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.2);
  padding: 10px;
  border-radius: 4px;
  margin-top: 15px;
  text-align: center;
  color: #ff4444;
  font-size: 0.9em;
}

.auto-repair-info {
  background: rgba(245, 19, 13, 0.1);
  border: 1px solid rgba(245, 19, 13, 0.2);
  padding: 10px;
  border-radius: 4px;
  margin-top: 15px;
  text-align: center;
  color: #F5130D;
  font-size: 0.9em;
}

.toggle-switch input:disabled + .toggle-slider {
  background-color: #666;
  cursor: not-allowed;
  opacity: 0.5;
}

.mechanic-card {
  background-color: #1a1a1a;
}

.fire-confirm-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background-color: rgba(0, 0, 0, 0.8);*/
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

.fire-confirm-content {
  background: linear-gradient(145deg, #1a1a1a, #222);
  padding: 30px;
  border-radius: 12px;
  border: 1px solid rgba(245, 19, 13, 0.3);
  box-shadow: 0 5px 25px rgba(245, 19, 13, 0.2);
  text-align: center;
  max-width: 400px;
  width: 90%;
  animation: slideIn 0.3s ease;
  transform-origin: center;
}

.fire-confirm-content h3 {
  color: #f5130d;
  margin-bottom: 20px;
  font-size: 1.4em;
  text-shadow: 0 0 10px rgba(245, 19, 13, 0.3);
}

.fire-confirm-content small {
  display: block;
  margin-top: 10px;
  color: #888;
  font-size: 0.9em;
}

.fire-confirm-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.fire-confirm-buttons button {
  padding: 10px 30px;
  border: none;
  border-radius: 6px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.fire-confirm-buttons button:first-child {
  background-color: #f5130d;
  color: white;
}

.fire-confirm-buttons button:first-child:hover {
  background-color: #d41109;
}

.fire-confirm-buttons button:last-child {
  background-color: #333;
  color: white;
}

.fire-confirm-buttons button:last-child:hover {
  background-color: #444;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { 
    transform: scale(0.9);
    opacity: 0;
  }
  to { 
    transform: scale(1);
    opacity: 1;
  }
} 