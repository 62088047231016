.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 18px 0;
  background-color: #111;
  color: #ffffff;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.5);
  border-top: 5px solid transparent;
  overflow: hidden;
  border-top: 2px solid #F5130D;
}

@keyframes pixelFallFooter {
  0% {
    transform: translateY(-100%);
    opacity: 1;
  }
  100% {
    transform: translateY(900%);
    opacity: 0;
  }
}

.footer .pixel {
  position: absolute;
  top: 0;
  width: 8px;
  height: 8px;
  background: #F5130D;
  animation: pixelFallFooter 10s infinite linear;
  z-index: 1;
  pointer-events: none;
}

.footer .pixel:nth-child(2) {
  left: 20%;
  animation-duration: 4s;
}

.footer .pixel:nth-child(3) {
  left: 40%;
  background: #F5130D;
  animation-duration: 2.5s;
}

.footer .pixel:nth-child(4) {
  left: 60%;
  background: #F5130D;
  animation-duration: 3.5s;
}

.footer .pixel:nth-child(5) {
  left: 80%;
  background: #F5130D;
  animation-duration: 2.8s;
}

.footer .pixel:nth-child(6) {
  left: 90%;
  width: 5px;
  height: 5px;
  background: #F5130D;
  animation-duration: 4s;
}

.footer-column-left {
  flex: 1;
  text-align: left;
  padding-left: 10px;
  z-index: 2;
}

.footer-column-center {
  text-align: center;
  z-index: 2;
}

.footer-column-right {
  flex: 1;
  text-align: right;
  padding-right: 10px;
  z-index: 2;
}

.footer p {
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.8em;
  line-height: 1.2em;
}

.copyright-text {
  color: #ffffff;
}

.social-icons {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  margin-right: 60px;
}

.social-icons a {
  color: #6176ff;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.social-icons a:hover {
  transform: scale(1.1);
  filter: brightness(1.1);
}

.x-twitter-icon .social-icon {
  width: 1.8em;
  margin-top: 3px;
}

.discord-icon .social-icon {
  width: 2.2em; /* Troque font-size por width */
}

.atomic-hub-icon .social-icon {
  width: 2.0em;
}

.social-icon {
  filter: invert(32%) sepia(100%) saturate(7463%) hue-rotate(-4deg) brightness(103%) contrast(92%);
  height: auto;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.1);
  /*filter: brightness(1.1);*/
}

.disclaimer-text {
  color: #ffcc00;
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.5em;
  margin: 0 20px 0px 10px;
  font-family: 'Press Start 2P', cursive;;
}

.disclaimer-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.disclaimer-content {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  position: relative;
  animation: fadeIn 0.3s ease-in-out;
}

.disclaimer-paragraph {
  color: #000000;
  font-size: 0.9em;
  line-height: 1.5;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  cursor: pointer;
}

.close-button:hover {
  color: #ff0000;
}

.disclaimer-title {
  margin-top: 0;
  font-family: 'Press Start 2P', cursive;
  font-size: 1.2em;
  color: #333333;
  text-align: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Make the social icons appear above the footer text on mobile devices */
@media (max-width: 768px) {
  .footer {
    flex-direction: column-reverse;
    align-items: center;
  }

  .footer-column-right {
    order: 1; /* Ensure the social icons appear above */
    margin-bottom: 10px;
  }

  .footer-column-center {
    /*order: 2;*/
  }

  .social-icons {
    margin-right: 0;
  }

  .disclaimer-text {
    margin: 5px 10px 20px;
  }
}
