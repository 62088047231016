/* Fonte customizada */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.race-animation-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.7); /* Fundo escuro para cobrir a tela toda */
  pointer-events: none; /* Para garantir que a animação não interfira com a UI */
  animation: screenFadeOut 1s forwards 5s; /* Tela escura desaparece após 5s */
}

@keyframes screenFadeOut {
  to {
    opacity: 0.5;
  }
}

.race-flag {
  width: 100%;
  height: 100%;
  background-image: url('../assets/bandeira.jpg'); /* Utilize o caminho correto para a imagem */
  background-size: cover;
  position: absolute;
  top: 0;
  left: -100%;
  animation: flagMove 2s forwards 4s; /* Animação de movimento da bandeira */
}

@keyframes flagMove {
  0% {
    left: -100%;
  }
  70% {
    left: 100%;
  }
  100% {
    left: 100%;
    opacity: 0;
  }
}

@keyframes flagWave {
  0%, 100% {
    transform: skewX(0deg);
  }
  50% {
    transform: skewX(10deg);
  }
}

.countdown {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 100px;
  color: white;
  font-family: 'Press Start 2P', sans-serif;
  opacity: 0;
  animation: countdownFade 1s forwards, scaleUpDown 1s infinite;
}

@keyframes countdownFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scaleUpDown {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.countdown-3 {
  animation-delay: 0s;
}

.countdown-2 {
  animation-delay: 1s;
}

.countdown-1 {
  animation-delay: 2s;
}

.countdown-go {
  animation-delay: 3s;
  color: #00ff00; /* Cor verde para o 'Go!' */
}

/* Efeito de tremor na tela */
@keyframes screenShake {
  0%, 100% {
    transform: translate(0, 0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translate(-5px, 0);
  }
  20%, 40%, 60%, 80% {
    transform: translate(5px, 0);
  }
}

.screen-shake {
  animation: screenShake 0.5s;
}

.race-animation-container.hidden {
  display: none;
}

/* Responsividade */
@media (max-width: 768px) {
  .countdown {
    font-size: 80px; /* Diminui o tamanho do texto para telas médias */
  }

  .race-flag {
    background-size: contain; /* Ajusta o tamanho da imagem da bandeira para telas menores */
  }
}

@media (max-width: 480px) {
  .countdown {
    font-size: 60px; /* Diminui ainda mais o tamanho do texto para dispositivos móveis */
  }

  .race-flag {
    background-size: cover; /* Garante que a imagem cubra a tela em dispositivos móveis */
    height: 80%; /* Ajuste a altura para melhor visualização */
  }
}
