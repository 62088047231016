/* server/styles/mapDetails.css */

@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.custom-map-details {
    text-align: center;
    margin-bottom: 20px;
    position: relative;
    background: linear-gradient(145deg, #1a1a1a, #333);
    padding: 20px;
    border: 2px solid #F5130D;
    border-radius: 10px;
    color: white;
    font-family: 'Press Start 2P', cursive;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    width: 790px;
    overflow: visible; /* Ensure content is contained */
}

/* Gradient Background Overlay */
.custom-map-details::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Removendo o background com padrão */
    opacity: 0.15;
    pointer-events: none;
    z-index: 1;
}

/* Ensure elements inside .custom-map-details are above the ::before */
.custom-map-selected,
.custom-map-info,
.custom-map-race-time,
.custom-map-racers,
.custom-map-total-coins,
.custom-nft-slot-container,
.nfts-available-text {
    position: relative;
    z-index: 2; /* Ensure these elements are above the gradient overlay */
}

.custom-map-selected {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #F5130D;
}

.custom-map-info {
    background: rgba(0, 0, 0, 0.594);
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.custom-map-info h4 {
    margin: 10px 0;
    color: #F5130D;
    font-size: 12px;
}

/* Estilos personalizados para Race Time, Racers, e Total Coins */
.custom-map-race-time,
.custom-map-racers,
.custom-map-total-coins {
    margin-bottom: 10px;
    font-size: 12px;
    color: #ffffff;
}

.custom-map-race-time {
    color: #ffffff;
    margin-top: 20px;
}

.custom-map-racers {
    color: #ffffff;
}

.custom-map-total-coins {
    color: #ffffff;
}

.custom-nft-slot-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.custom-nft-slot {
    position: relative;
    width: 75px;
    height: 75px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #F5130D;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease;
}

.custom-nft-slot:hover {
    transform: scale(1.05);
}

.custom-nft-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px;
}

.custom-nft-name {
    margin-bottom: 5px;
    font-size: 8px;
    color: #F5130D;
    text-align: center;
    font-family: 'Press Start 2P', cursive;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom-car-rarity {
    font-size: 8px;
    color: #fff;
    margin-top: 2px;
}

/* Custom Tooltip for NFT Attributes */
.mapdetails-nft-tooltip {
    position: absolute;
    bottom: 110%;
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
    background: rgba(0, 0, 0, 0.9);
    color: white;
    border: 1px solid #F5130D;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    transition: opacity 0.3s ease;
}

.mapdetails-nft-tooltip-header {
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #F5130D;
}

.mapdetails-nft-tooltip-list {
    list-style: none;
    padding: 10px;
    margin: 0;
}

.mapdetails-nft-tooltip-item {
    font-size: 9px;
    text-align: left;
    margin-bottom: 8px;
    line-height: 1.4;
}

.mapdetails-nft-tooltip-item .attribute-name {
    color: #F5130D;
}

.mapdetails-nft-tooltip-item .attribute-value {
    color: #ffffff;
}

/* Novos estilos para informações de Drop */
.mapdetails-nft-drop-info {
    margin-top: 10px;
    font-size: 9px;
}

.mapdetails-nft-drop-info div {
    margin-bottom: 5px;
}

.nfts-available-text {
    font-size: 12px;
    color: #F5130D;
    margin-top: 25px;
}

.custom-map-description {
    font-size: 9px;
    line-height: 1.4;
    font-weight: 400;
}

.boosted-templates-container {
    margin-top: 20px;
    background-color: #F5130D; /* Fundo diferente para se destacar */
    padding: 10px;
    border-radius: 5px;
}

.boosted-templates-text {
    font-weight: bold;
    margin-bottom: 10px;
}

.boosted-slot {
    background-color: #ffbc00; /* Fundo ainda mais diferenciado */
}

@media (max-width: 768px) {
    .custom-map-details {
        width: 580px;
    }
}

@media (max-width: 480px) {
    .custom-map-details {
        width: 320px;
    }

    .mapdetails-nft-tooltip {
        position: absolute;
        bottom: 110%;
        left: 50%;
        transform: translateX(-50%);
        width: 200px;
        background: rgba(0, 0, 0, 0.9);
        color: white;
        border: 1px solid #F5130D;
        border-radius: 8px;
        padding: 10px;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
        z-index: 10;
        transition: opacity 0.3s ease;
    }

    .mapdetails-nft-tooltip-item {
        font-size: 7px;
    }
}

.map-stat-label {
    font-size: 0.80em;
    color: #ffffff;
    margin-right: 8px;
}

.map-stat-value {
    font-size: 0.80em;
    color: #fff;
    /*background: rgba(245, 19, 13, 0.1);*/
    padding: 2px 8px;
    border-radius: 4px;
}
