/* Overlay */
.ongoing-races-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.ongoing-races-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1a1a;
  padding: 20px;
  border: 2px solid #F5130D;
  z-index: 1000;
  width: 80%;
  max-width: 700px;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 5px;
  color: white;
  font-family: 'Press Start 2P', sans-serif;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.ongoing-races-modal.show { display: block; }

.ongoing-races-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #F5130D;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.ongoing-races-header h2 {
  /*margin: 0;*/
  color: #F5130D;
  font-size: 24px;
  font-weight: 700;
}

.close-button-ongoing {
  background-color: #F5130D;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 14px;
  position: absolute;
  top: 10px;
  right: 10px;
  border: 2px solid #c0392b;
  box-shadow: 0 4px #7d240f;
  transition: all 0.3s ease;
}

.close-button-ongoing:hover {
  background-color: #ff6161;
  box-shadow: 0 6px #c0392b;
  transform: scale(1.05);
}

.close-button-ongoing:active {
  box-shadow: 0 2px #d14727;
  transform: translateY(2px);
}

.ongoing-race-columns {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
}

.ongoing-race-column {
  flex: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ongoing-race-map-column {
  border-right: 2px solid #F5130D;
  padding-right: 10px;
}

.ongoing-race-details-column {
  padding-left: 10px;
  width: 100%;
}

.ongoing-race-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  background-color: #1a1a1a;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  border: 2px solid #F5130D;
}

.ongoing-race-map-image,
.ongoing-race-car-image {
  width: 120px;
  height: 70px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}

.ongoing-race-map-name {
  text-align: center;
  font-size: 11px;
  color: #F5130D;
  width: 120px;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1.2;
  margin: 13px;
}

.ongoing-race-car-name {
  font-size: 12px;
  margin-bottom: 5px;
  text-align: center;
  width: 450px;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1.2;
}

.ongoing-race-time {
  font-size: 13px;
  color: #F5130D;
  text-align: center;
  line-height: 1.2;
}

.toggle-button {
  background: none;
  border: none;
  color: #F5130D;
  font-size: 18px;
  cursor: pointer;
  margin-top: 10px;
}

.race-additional-details {
  margin-top: 10px;
  background: #1e1e1e;
  padding: 15px;
  border-radius: 5px;
  color: #e5e5e5;
  font-family: 'Press Start 2P', sans-serif;
  font-size: 12px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

.race-additional-details h4 {
  grid-column: 1 / -1;
  margin-bottom: 10px;
  color: #ff6161;
  text-align: center;
}

.race-additional-details .time-info {
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-around;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 17px;
}

.race-additional-details .detail-section {
  background: #2c2c2c;
  padding: 10px;
  border-radius: 5px;
}

.race-additional-details h5 {
  margin: 0 0 5px;
  color: #ffd700;
  font-size: 13px;
}

.race-additional-details ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 10px;
  margin-top: 15px;
}

.race-additional-details li {
  margin-bottom: 5px;
  line-height: 16px;
}

details {
  background: #2c2c2c;
  padding: 8px;
  border-radius: 5px;
  margin-top: 5px;
}

details summary {
  cursor: pointer;
  color: #ffd700;
  outline: none;
}

details[open] summary {
  color: #ff6161;
}

.no-ongoing-races {
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
  color: #F5130D;
}

.ongoing-races-modal::-webkit-scrollbar {
  width: 14px;
}

.ongoing-races-modal::-webkit-scrollbar-track {
  background: #333;
}

.ongoing-races-modal::-webkit-scrollbar-thumb {
  background-color: #F5130D;
  border-radius: 0px;
  border: 2px solid #1a1a1a;
}

.ongoing-races-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 30px;
}

.ongoing-races-pagination button {
  background-color: #F5130D;
  color: white;
  border: 2px solid #c0392b;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 10px;
  font-family: 'Press Start 2P', sans-serif;
  font-size: 12px;
  transition: all 0.3s ease;
}

.ongoing-races-pagination button:hover:not(:disabled) {
  background-color: #ff6161;
  box-shadow: 0 6px #c0392b;
  transform: scale(1.05);
}

.ongoing-races-pagination button:active:not(:disabled) {
  box-shadow: 0 2px #d14727;
  transform: translateY(2px);
}

.ongoing-races-pagination button:disabled {
  background-color: #555;
  border-color: #777;
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
}

.ongoing-races-pagination span {
  color: #F5130D;
  font-family: 'Press Start 2P', sans-serif;
  font-size: 14px;
}

.ongoing-races-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.ongoing-race-car-items {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ongoing-race-items {
  display: flex;
  gap: 5px;
}

.ongoing-race-item-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  border: 2px solid #F5130D;
}

/* Responsividade */
@media (max-width: 768px) {
  .ongoing-races-modal {
    width: 90%;
    padding: 15px;
  }
  .ongoing-race-columns {
    flex-direction: column;
    align-items: center;
  }
  .ongoing-race-map-column {
    border-right: none;
    padding-right: 0;
    border-bottom: 2px solid #F5130D;
    margin-bottom: 10px;
  }
  .race-additional-details {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .ongoing-races-modal {
    padding: 10px;
  }
  .race-additional-details {
    grid-template-columns: 1fr;
  }

  .ongoing-races-header h2 {
    font-size: 18px;
  }

  .ongoing-race-item-image {
    width: 30px;
    height: 30px;
  }
}
