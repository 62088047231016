body {
  margin: 0;
  font-family: 'Press Start 2P', Arial, sans-serif;
  background-color: #1e1e1e;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
}

.header-container {
  width: 785px;
  /*margin: 25px auto;*/
  border: 2px solid #F5130D;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0,0,0,0.8);
  background: linear-gradient(145deg, #1a1a1a, #333);
  position: relative;
}

.header-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background: linear-gradient(45deg, rgba(255,255,255,0.1) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0.1) 75%, transparent 75%, transparent);*/
  background-size: 50px 50px;
  opacity: 0.15;
  pointer-events: none;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: relative;
}

.header-logo-container {
  flex: 0 0 auto;
}

.header-logo {
  width: 40px;
  height: auto;
  cursor: pointer;
  transform: scale(1.3);
  transition: transform 0.3s;
}

.header-logo:hover {
  transform: scale(1.25);
}

.header-buttons-container {
  display: flex;
  align-items: center;
}

.button {
  background-color: #F5130D;
  color: white;
  border: 2px solid #c0392b;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 10px;
  font-family: 'Press Start 2P', sans-serif;
  box-shadow: 0 4px #7d240f;
  transition: all 0.3s ease;
  font-size: 10px;
  margin-left: 15px;
  z-index: 2;
}

.button:hover {
  background-color: #ff6161;
  box-shadow: 0 6px #c0392b;
  transform: scale(1.05);
}

.button:active {
  box-shadow: 0 2px #d14727;
  transform: translateY(2px);
}

.admin-panel-button { margin-right: auto; }

.img-wallet-user {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -20%);
  background-color: #1e1e1e;
  border: 2px solid #F5130D;
  border-radius: 50%;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  z-index: 2;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 15px rgba(0,0,0,0.7);
  cursor: pointer;
}

.user-avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.header-main {
  display: flex;
  justify-content: space-between;
  padding: 5px 50px;
  color: #fff;
  background: linear-gradient(145deg, #1a1a1a, #333);
  border-top: 2px solid #F5130D;
}

.header-main p {
  /*margin: 5px 0;*/
  font-weight: normal;
}

.info-card {
  background: rgba(255,255,255,0.1);
  border: 1px solid rgba(255,255,255,0.2);
  box-shadow: 0 4px 6px rgba(0,0,0,0.4);
  border-radius: 10px;
  padding: 10px 15px;
  margin: 10px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  text-align: center;
  color: #fff;
  font-size: 10px;
}

.header-bottom {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(145deg, rgba(26, 26, 26, 0.9), rgba(51, 51, 51, 0.9));
  color: #fff;
  padding: 15px 20px;
  border-radius: 0 0 10px 10px;
  border-top: 1px solid rgba(245, 19, 13, 0.5);
  gap: 15px;
}

.level-user,
.ranking-user {
  background: linear-gradient(145deg, #F5130D, #ff6161);
  padding: 8px 15px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  min-width: 100px;
  box-shadow: 0 4px 15px rgba(245, 19, 13, 0.3);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.level-user:hover,
.ranking-user:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(245, 19, 13, 0.4);
}

.xp-bar-card.info-card {
  flex: 1;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(245, 19, 13, 0.3);
  padding: 10px 15px;
  border-radius: 8px;
  margin: 0;
  min-width: 0;
}

.xp-bar-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.xp-bar {
  position: relative;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(245, 19, 13, 0.3);
  border-radius: 6px;
  width: 100%;
  height: 16px;
  overflow: hidden;
}

.xp-progress {
  background: linear-gradient(90deg, #F5130D, #ff6161);
  height: 100%;
  position: relative;
  box-shadow: 0 0 10px rgba(245, 19, 13, 0.5);
  transition: width 0.3s ease-in-out;
}

.xp-label {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 10px;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
  white-space: nowrap;
  z-index: 1;
}

.xp-percent {
  font-size: 10px;
  color: #ccc;
  text-align: center;
  margin-top: 4px;
}

.header-top,
.header-main,
.header-bottom {
  /* Ensure content does not overflow horizontally */
  overflow-x: hidden;
}

.wallet-info, .race-info {
  /*background: linear-gradient(145deg, rgba(26, 26, 26, 0.9), rgba(51, 51, 51, 0.9));*/
  border: 1px solid rgba(245, 19, 13, 0.5);
  box-shadow: 0 4px 15px rgba(245, 19, 13, 0.15);
  border-radius: 12px;
  padding: 15px;
  margin: 10px;
  min-width: 200px;
  backdrop-filter: blur(5px);
  transition: all 0.3s ease;
}

.wallet-info:hover, .race-info:hover {
  border-color: rgba(245, 19, 13, 0.8);
  box-shadow: 0 6px 20px rgba(245, 19, 13, 0.25);
  transform: translateY(-2px);
}

.wallet-info .body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.wallet-address {
  font-size: 10px;
  color: #ccc;
  background: rgba(0, 0, 0, 0.2);
  padding: 8px;
  border-radius: 6px;
  word-break: break-all;
  margin: 0;
}

.wallet-balance {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0 10px rgba(245, 19, 13, 0.5);
  margin: 0;
  padding: 4px 0;
}

.race-info-header {
  margin-bottom: 10px;
}

.race-info-header p {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 0;
  font-size: 12px;
  color: #fff;
  text-shadow: 0 0 10px rgba(245, 19, 13, 0.5);
}

.race-info .progress-bar {
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(245, 19, 13, 0.3);
  height: 8px;
  margin: 10px 0;
  border-radius: 4px;
  overflow: hidden;
}

.race-info .progress {
  background: linear-gradient(90deg, #F5130D, #ff6161);
  box-shadow: 0 0 10px rgba(245, 19, 13, 0.5);
  transition: width 0.3s ease-in-out;
}

.race-info .time {
  font-size: 10px;
  color: #ccc;
  margin: 8px 0 0 0;
}

.add-races-button {
  background: linear-gradient(145deg, #F5130D, #ff6161);
  color: white;
  border: none;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin-left: 5px;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(245, 19, 13, 0.4);
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.add-races-button:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(245, 19, 13, 0.6);
}

.add-races-button:active {
  transform: scale(0.95);
  box-shadow: 0 2px 4px rgba(245, 19, 13, 0.4);
}

.add-races-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(145deg, rgba(255,255,255,0.2), transparent);
  border-radius: 50%;
}

/* Responsividade */
@media (max-width: 768px) {
  .header-container {
    width: 95%;
    margin: 10px auto;
  }
  .header-top {
    flex-direction: column;
    align-items: center;
  }
  .header-logo {
    width: 60px;
    margin-bottom: 25px;
    margin-top: 10px;
  }
  .header-buttons-container {
    flex-direction: column;
  }
  .button {
    padding: 8px 16px;
    font-size: 10px;
    margin: 5px 0;
  }
  .header-main {
    flex-direction: column;
    padding: 10px 20px;
    text-align: center;
  }
  .wallet-info,
  .race-info,
  .level-user,
  .xp-bar-card,
  .ranking-user {
    font-size: 12px;
  }
  .img-wallet-user {
    width: 50px;
    height: 50px;
    top: 15px;
    left: 15px;
    right: auto;
    transform: none;
    padding: 5px;
  }
  .info-card {
    margin: 5px 0;
  }
  .header-bottom {
    flex-direction: column;
    padding: 10px;
    gap: 10px;
  }
  .level-user,
  .ranking-user {
    /*width: 100%;*/
    padding: 8px;
    font-size: 11px;
    min-width: 0;
  }
  .xp-bar-card.info-card {
    /*width: 100%;*/
    padding: 8px;
    margin: 5px 0;
  }
  .xp-bar {
    height: 12px;
  }
  .xp-label {
    font-size: 9px;
    white-space: nowrap;
  }
  .xp-percent {
    font-size: 9px;
    margin-top: 2px;
  }
  .wallet-info,
  .race-info {
    margin: 5px 0;
    padding: 10px;
    min-width: 0;
  }
  .wallet-address {
    font-size: 9px;
    padding: 6px;
  }
  .wallet-balance {
    font-size: 11px;
  }
  .race-info-header p {
    font-size: 11px;
  }
  .add-races-button {
    width: 16px;
    height: 16px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .header-container {
    width: 98%;
    margin: 5px auto;
  }
  .header-logo {
    width: 50px;
  }
  .xp-bar {
    height: 10px;
    width: 100%;
  }
  .level-user,
  .ranking-user {
    padding: 6px;
    font-size: 10px;
  }
  .xp-label {
    font-size: 8px;
  }
  .xp-percent {
    font-size: 8px;
  }
}
