/* Overlay escuro cobrindo toda a tela */
.store-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Fundo escuro translúcido */
    z-index: 999; /* Sobrepõe elementos comuns, mas fica atrás do modal */
  }
  
  /* Container principal do modal */
  .store-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    background-color: #1a1a1a;
    border: 2px solid #F5130D;
    z-index: 1000;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    color: white;
    font-family: 'Press Start 2P', sans-serif;
    text-align: center;
  }
  
  /* Conteúdo interno do modal */
  .store-modal-content {
    background-color: transparent;
    margin: 0 auto;
    padding: 0;
    border: none;
    width: 100%;
    max-width: 100%;
    text-align: center;
    border-radius: 5px;
  }
  
  /* Botão de fechar (X) */
  .store-close {
    background-color: #F5130D;
    color: white;
    border: 2px solid #c0392b;
    padding: 7px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    position: absolute;
    top: 10px;
    right: 10px;
    box-shadow: 0 4px #7d240f;
    transition: all 0.3s ease;
    font-family: 'Press Start 2P', sans-serif;
  }
  
  .store-close:hover {
    background-color: #ff6161;
    box-shadow: 0 6px #c0392b;
    transform: scale(1.05);
  }
  
  .store-close:active {
    box-shadow: 0 2px #d14727;
    transform: translateY(2px);
  }
  
  /* Título do modal */
  .store-title {
    font-size: 20px;
    color: #F5130D;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    text-transform: uppercase;
    letter-spacing: 1px;
    background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
    border: 2px solid #F5130D;
    padding: 8px 16px;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 20px;
  }
  
  /* Texto de instruções */
  .store-instructions {
    font-size: 11px;
    color: #ddd;
    margin-bottom: 15px;
    line-height: 20px;
  }
  
  /* Container das opções (antigas) substituímos para um card genérico. */
  .pack-option {
    background: #292929;
    color: white;
    margin: 10px auto;
    padding: 15px;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    border: 2px solid #F5130D;
    max-width: 350px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .pack-option:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.7);
  }
  
  /* Título dentro do pack-option */
  .pack-option h3 {
    margin-bottom: 5px;
    color: #fff;
    text-shadow: 1px 1px 2px #000;
    font-size: 16px;
  }
  
  /* Texto dentro do pack-option */
  .pack-option p {
    font-size: 12px;
    margin-bottom: 10px;
    color: #fabf1f;
  }
  
  /* Botão de comprar */
  .pack-option button {
    background-color: #F5130D;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-family: 'Press Start 2P', sans-serif;
    font-size: 12px;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .pack-option button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.7);
    background-color: #ff7676;
  }
  
  /* Mensagem de status */
  .status-msg {
    color: #0ff;
    margin-top: 10px;
    font-size: 12px;
  }
  
  /* Mensagem de erro */
  .error-msg {
    color: #ff4444;
    margin-top: 10px;
    font-size: 12px;
  }
  
  /* Barra de rolagem customizada */
  .store-modal::-webkit-scrollbar {
    width: 12px;
  }
  
  .store-modal::-webkit-scrollbar-track {
    background: #333;
  }
  
  .store-modal::-webkit-scrollbar-thumb {
    background-color: #F5130D;
    border: 2px solid #1a1a1a;
  }
  
  /* Slider (range) */
  #limitsRange {
    -webkit-appearance: none; /* remove padrão do Chrome */
    width: 80%;
    background: transparent;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #limitsRange:focus {
    outline: none;
  }
  /* Trilho do slider */
  #limitsRange::-webkit-slider-runnable-track {
    height: 6px;
    background: #444;
    border: 2px solid #F5130D;
    border-radius: 3px;
  }
  #limitsRange::-webkit-slider-runnable-track:hover {
    background: #555;
  }
  /* Thumb do slider */
  #limitsRange::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    background: #F5130D;
    border-radius: 50%;
    margin-top: -5px;
    cursor: pointer;
    transition: background 0.3s;
    box-shadow: 0 0 2px #000;
  }
  #limitsRange::-webkit-slider-thumb:hover {
    background: #ff7676;
  }

  .store-info {
    font-size: 11px;
    line-height: 20px;
    color: #F5130D;
  }
  
  /* Responsividade */
  @media (max-width: 768px) {
    .store-modal {
      width: 90%;
      max-width: 500px;
      padding: 15px;
    }
  
    .pack-option {
      margin: 10px;
      font-size: 13px;
    }
  
    .pack-option h3 {
      font-size: 14px;
    }
  
    .pack-option p {
      font-size: 11px;
    }
  
    .pack-option button {
      font-size: 11px;
      padding: 8px 16px;
    }
  }
  
  @media (max-width: 480px) {
    .store-modal {
      width: 95%;
      padding: 10px;
    }
  
    .pack-option {
      margin: 6px;
      padding: 12px;
      font-size: 12px;
    }
  
    .pack-option h3 {
      font-size: 13px;
    }
  
    .pack-option p {
      font-size: 10px;
    }
  
    .pack-option button {
      font-size: 10px;
      padding: 6px 12px;
    }
  }
  