/* client/src/styles/Casino.css */

.casino-page {
    padding: 30px;
    background-color: #1a1a1a;
    color: #ffffff;
    min-height: 100vh;
    font-family: 'Arial', sans-serif;
}

.casino-page h1 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 3em;
    color: #ffcc00;
    text-shadow: 2px 2px #000;
}

.casino-section {
    background-color: #2c2c2c;
    padding: 25px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s;
}

.casino-section:hover {
    transform: scale(1.02);
}

.casino-section h2 {
    margin-bottom: 20px;
    font-size: 2em;
    color: #ffcc00;
    text-shadow: 1px 1px #000;
}

.filters {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
}

.filter-group {
    flex: 1;
    min-width: 200px;
}

.filter-group h4 {
    margin-bottom: 10px;
    color: #ffcc00;
}

.filter-label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    cursor: pointer;
}

.filter-label input {
    margin-right: 8px;
}

.toggle-label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.toggle-label input {
    margin-right: 8px;
}

.refresh-button,
.bet-button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 15px;
    transition: background-color 0.3s, transform 0.2s;
}

.refresh-button {
    background-color: #00ccff;
    color: #1a1a1a;
}

.refresh-button:hover {
    background-color: #00b3e6;
    transform: translateY(-2px);
}

.refresh-button:disabled {
    background-color: #999999;
    cursor: not-allowed;
    transform: none;
}

.bet-button {
    background-color: #ff6666;
    color: #1a1a1a;
}

.bet-button:hover {
    background-color: #e65c5c;
    transform: translateY(-2px);
}

.bet-button:disabled {
    background-color: #999999;
    cursor: not-allowed;
    transform: none;
}

.selected-nfts {
    margin-bottom: 20px;
}

.selected-nfts h4 {
    color: #ffcc00;
    margin-bottom: 10px;
}

.selected-nfts-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.selected-nft-item {
    background-color: #3a3a3a;
    border-radius: 8px;
    padding: 10px;
    width: 150px;
    text-align: center;
    position: relative;
}

.selected-nft-item img {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.selected-nft-item p {
    margin: 5px 0;
    font-size: 0.9em;
}

.selected-nft-item button {
    background-color: #ff3333;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 0.8em;
    transition: background-color 0.3s;
}

.selected-nft-item button:hover {
    background-color: #cc0000;
}

.nft-grid-container {
    margin-top: 20px;
}

.nft-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
}

.nft-item {
    background-color: #3a3a3a;
    border-radius: 8px;
    padding: 10px;
    cursor: pointer;
    position: relative;
    transition: transform 0.2s, box-shadow 0.3s;
}

.nft-item:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.nft-item.selected {
    border: 3px solid #ffcc00;
}

.nft-item.inactive {
    opacity: 0.6;
    cursor: not-allowed;
}

.nft-image {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
}

.nft-info {
    text-align: center;
}

.nft-name {
    font-weight: bold;
    margin-bottom: 5px;
}

.nft-id,
.nft-rarity {
    font-size: 0.85em;
    margin-bottom: 3px;
}

.nft-status {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: rgba(255, 0, 0, 0.8);
    color: #ffffff;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 0.8em;
    text-transform: uppercase;
}

.nft-status.in-race {
    background-color: rgba(0, 255, 0, 0.8);
}

.nft-status.in-stake {
    background-color: rgba(255, 165, 0, 0.8);
}

.no-nfts {
    text-align: center;
    color: #bbbbbb;
    font-size: 1.1em;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.matches-list {
    list-style-type: none;
    padding: 0;
    max-height: 400px;
    overflow-y: auto;
}

.match-item {
    padding: 15px;
    margin-bottom: 10px;
    background-color: #3a3a3a;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
}

.match-item:hover {
    background-color: #4a4a4a;
}

.match-item strong {
    color: #ffcc00;
}

.no-matches {
    text-align: center;
    color: #bbbbbb;
    font-size: 1.2em;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}

.pagination-button {
    padding: 8px 12px;
    background-color: #444444;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination-button:hover {
    background-color: #555555;
}

.pagination-button.active {
    background-color: #ffcc00;
    color: #1a1a1a;
}
