/* src/components/Home.css */

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Press Start 2P', cursive;
  color: #fff;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

/* Estilo para a logo */
.logo-home-image {
  width: 300px; /* Ajuste o tamanho conforme necessário */
  height: auto;
  margin-bottom: 20px; /* Espaçamento entre a logo e o botão */
}

/* Estilo para a logo e o botão */
.logo-home-container {
  display: flex;
  flex-direction: column; /* Coloca a logo e o botão em uma coluna */
  align-items: center; /* Centraliza o conteúdo */
  margin-bottom: 100px; /* Espaçamento abaixo da seção */
}

.home-layout {
  position: relative;
  display: flex;
  flex-direction: column; /* Alinhar os elementos verticalmente */
  justify-content: flex-start; /* Garantir que o conteúdo principal fique no topo */
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: #000;
  overflow: hidden;
  background: linear-gradient(145deg, #111, #1c1c1c);
  animation: lightMove 10s ease-in-out infinite; /* Animação de luz movendo-se sobre a imagem de fundo */
}

@keyframes lightMove {
  0% { background-position: 0% 0%; }
  50% { background-position: 50% 50%; }
  100% { background-position: 0% 0%; }
}

/*.home-layout::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 65%;
  background-image: url('../assets/pixelracesbackground.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  /*animation: randomZoom 30s ease-in-out infinite; /* Animação de zoom aleatório *
}*/

@keyframes randomZoom {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
    transform-origin: center;
  }
}

.home-content::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Camada escura para destacar os botões */
  z-index: 2;
}

.home-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1; /* Fazer o conteúdo principal ocupar o espaço vertical */
  width: 100%;
  z-index: 3; /* Certificar-se de que está acima do fundo */
  background-image: url('../assets/pixelracesbackground.jpg'); /* Imagem de fundo */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;  
  /*animation: randomZoom 60s ease-in-out infinite; /* Animação de zoom aleatório */
}

@keyframes randomZoom {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
    transform-origin: center;
  }
}

.center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  z-index: 4;
  text-align: center;
}

.home-main-content {
  animation: fadeIn 1.5s ease-out forwards;
  opacity: 0;
  margin-top: 220px;
}

@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}

.title {
  font-size: 3em;
  margin-bottom: 20px;
  color: #ffffff;
}

.play-button {
  padding: 15px 50px;
  font-size: 1.5em;
  background-color: #F5130D;
  color: #fff;
  border: 2px solid #ff6161; /* Slightly different color for the border */
  border-radius: 5px; /* No rounded corners for a more retro pixelated look */
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  font-family: 'Press Start 2P', cursive;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 0.3s ease;
  /*box-shadow: 0 0 15px rgba(255, 65, 65, 0.5), inset 0 0 0 0 #ff7676;*/
  /* Adding pixelated border effect */
  background-image: repeating-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2) 1px,
    rgba(0, 0, 0, 0) 1px,
    rgba(0, 0, 0, 0) 4px
  );
}

.play-button:hover {
  background-color: #ff7676; /* Lighter color on hover */
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(255, 65, 65, 0.7), inset 0 0 15px #F5130D;
}

.play-button:active {
  transform: scale(0.98);
  box-shadow: 0 0 10px rgba(255, 65, 65, 0.9), inset 0 0 20px #d14727;
}

.play-button::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border: 2px solid #F5130D;
  z-index: -1;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.play-button:hover::before {
  opacity: 1;
  border-color: #ff6161;
}

@keyframes pulseEffect {
  0%, 100% {
    box-shadow: 0 0 15px rgba(255, 65, 65, 0.5), inset 0 0 0 0 #ff7676;
  }
  50% {
    box-shadow: 0 0 25px rgba(255, 65, 65, 1), inset 0 0 10px #ff7676;
  }
}

.play-button.pulsing {
  animation: pulseEffect 2s infinite;
}



/* Responsividade */
@media (max-width: 768px) {
  .title {
    font-size: 3em;
  }

  .play-button {
    padding: 10px 40px;
    font-size: 1.2em;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 2em;
  }

  .play-button {
    padding: 10px 30px;
    font-size: 1em;
  }
}


/* Seção de parcerias */
.partnerships-section {
  /*margin-top: 50px;*/
  width: 100%; 
  text-align: center;
  z-index: 4; 
  padding: 20px;
  background-color: #0e0e0e
}

.partnerships-section h3 {
font-size: 15px;
}

.partnerships-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px; /* Maior espaçamento entre os slots */
  margin-top: 50px;
  margin-bottom: 50px;
}

/* Estilo dos slots com tema pixel art */
.partnership-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 150px;
  background-color: #1c1c1c;
  /*border: 2px solid #F5130D; */
  border-radius: 5px; 
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.partnership-item:hover {
  transform: translateY(-10px);
  /*box-shadow: 0 0 25px rgba(255, 65, 65, 0.8); */
}

/* Estilo para a imagem dentro do slot - agora redonda */
.partnership-item img {
  width: 80px;
  height: 80px;
  object-fit: cover; /* Ajustar o conteúdo da imagem */
  margin-bottom: 10px;
  border-radius: 10%; /* Tornar as imagens redondas */
  /*border: 2px solid #ffffff;*/
  padding: 5px;
  /*background-color: #000; /* Fundo da imagem com cor neutra */
}

/* Nome da coleção - agora limitando o texto */
.partnership-item p {
  font-size: 0.6em;
  color: #ffffff;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif; /* Fonte Montserrat aplicada */
  margin: 0;
  letter-spacing: 0.5px;
  /*max-width: 90%; /* Limitar o texto para não sair do slot */
  /*overflow: hidden; /* Esconder o excesso de texto */
  /*text-overflow: ellipsis; /* Mostrar "..." caso o texto seja muito longo */
  white-space: nowrap; /* Evitar quebra de linha */
  text-align: center; /* Centralizar o nome */
  margin-top: 5px;
}

/* Remover o sublinhado do link */
.partnership-item a {
  text-decoration: none;
}

/* Opcional: mudar a cor do texto do link ao passar o mouse (hover) */
.partnership-item a:hover {
  color: #ff7676; /* Você pode escolher qualquer cor que preferir */
}

/* Responsividade */
@media (max-width: 768px) {
  .partnership-item {
    width: 150px;
    height: 200px;
  }

  .partnership-item img {
    width: 100px;
    height: 100px;
  }

  .partnership-item p {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .partnership-item {
    width: 130px;
    height: 180px;
  }

  .partnership-item img {
    width: 80px;
    height: 80px;
  }

  .partnership-item p {
    font-size: 0.9em;
  }
}

/* Seção de Notícias */
.news-section {
  width: 100%;
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
  position: relative;
  z-index: 5;
}

.news-section h3 {
  color: #F5130D;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.news-carousel-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 80px;
  box-sizing: border-box;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(245, 19, 13, 0.8);
  border: none;
  color: white;
  padding: 15px;
  cursor: pointer;
  font-size: 20px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 2;
}

.carousel-button:hover {
  background: rgba(245, 19, 13, 1);
}

.carousel-button:disabled {
  background: rgba(128, 128, 128, 0.5);
  cursor: not-allowed;
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  transition: transform 0.3s ease;
  justify-content: center;
}

/* Classe especial para quando há apenas 1 notícia */
.news-grid.single-news {
  grid-template-columns: minmax(auto, 400px);
  justify-content: center;
}

/* Classe especial para quando há apenas 2 notícias */
.news-grid.two-news {
  grid-template-columns: repeat(2, minmax(auto, 400px));
  justify-content: center;
}

.news-item {
  background: #1c1c1c;
  border: 2px solid #F5130D;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  z-index: 10;
}

.news-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 20px rgba(245, 19, 13, 0.5);
}

.news-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.news-info {
  padding: 1rem;
}

.news-info h4 {
  color: #fff;
  margin: 0 0 0.5rem 0;
  font-size: 1.2rem;
}

.news-date {
  color: #999;
  font-size: 0.7rem;
}

/* Modal de Notícias */
.news-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.news-modal-content {
  background: #1c1c1c;
  border: 2px solid #F5130D;
  border-radius: 8px;
  padding: 2rem;
  max-width: 1200px;
  width: 90%;
  max-height: 68vh;
  overflow-y: auto;
  position: relative;
}

.news-modal-content h2 {
  color: #F5130D;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

.news-modal-content img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 4px;
  margin: 1rem 0;
}

.news-content {
  margin: 1rem 0;
  line-height: 1.6;
}

.news-content p {
  white-space: pre-wrap;
  word-wrap: break-word;
  /*font-family: 'Montserrat', sans-serif;*/
  font-size: 0.8rem;
  color: #fff;
  margin-bottom: 1rem;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: #F5130D;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0.5rem;
  transition: transform 0.3s ease;
}

.close-button:hover {
  transform: scale(1.1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsividade */
@media (max-width: 768px) {
  .news-section {
    padding: 0 10px;
  }

  .news-carousel-container {
    padding: 0 40px;
  }

  .carousel-button {
    width: 40px;
    height: 40px;
    font-size: 16px;
    padding: 10px;
  }

  .carousel-button.prev {
    left: 5px;
  }

  .carousel-button.next {
    right: 5px;
  }

  .news-grid {
    grid-template-columns: 1fr;
    max-width: 100%;
    margin: 0 auto;
  }

  .news-grid.single-news,
  .news-grid.two-news {
    grid-template-columns: 1fr;
    max-width: 100%;
  }

  .news-item {
    max-width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .news-section h3 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .news-carousel-container {
    padding: 0 60px;
  }

  .carousel-button {
    width: 30px;
    height: 30px;
    font-size: 14px;
    padding: 8px;
  }

  .carousel-button.prev {
    left: 17px;
  }

  .carousel-button.next {
    right: 17px;
  }

  .news-grid,
  .news-grid.two-news,
  .news-grid.single-news {
    grid-template-columns: 1fr;
    gap: 15px;
    width: 100%;
  }

  .news-item {
    width: 100%;
    margin: 0 auto;
  }

  .news-modal-content {
    background: #1c1c1c;
    border: 2px solid #F5130D;
    border-radius: 8px;
    padding: 2rem;
    max-width: 1200px;
    width: 80%;
    max-height: 64vh;
    overflow-y: auto;
    position: relative;
  }
}

.startup-message-settings {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(245, 19, 13, 0.3);
}

.startup-message-settings label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #999;
  font-size: 0.9rem;
  cursor: pointer;
}

.startup-message-settings input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.featured-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: #F5130D;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  z-index: 1;
}

.server-status-indicator {
  width: auto;
  max-width: 300px;
  padding: 8px 20px;
  text-align: center;
  font-size: 0.7rem;
  font-family: 'Press Start 2P', cursive;
  background-color: #1c1c1c;
  border: 2px solid #F5130D;
  border-radius: 4px;
  z-index: 1000;
  transition: all 0.3s ease;
  animation: pixelPulse 2s infinite;
  color: #fff;
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  box-shadow: 0 0 10px rgba(245, 19, 13, 0.3);
  /* Efeito pixel art nas bordas */
  background-image: 
    linear-gradient(45deg, transparent 2%, rgba(245, 19, 13, 0.1) 3%, transparent 3%),
    linear-gradient(-45deg, transparent 2%, rgba(245, 19, 13, 0.1) 3%, transparent 3%);
  background-size: 6px 6px;
  line-height: 24px;
}

/* Animação de pulso estilo pixel art */
@keyframes pixelPulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
}

/* Adiciona ícones pixelados nas laterais */
.server-status-indicator::before,
.server-status-indicator::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #F5130D;
  top: 50%;
  transform: translateY(-50%);
}

.server-status-indicator::before {
  left: -4px;
  clip-path: polygon(0 0, 100% 50%, 0 100%);
}

.server-status-indicator::after {
  right: -4px;
  clip-path: polygon(100% 0, 100% 100%, 0 50%);
}

/* Responsividade */
@media (max-width: 768px) {
  .server-status-indicator {
    font-size: 0.6rem;
    padding: 6px 15px;
    max-width: 250px;
  }
}

@media (max-width: 480px) {
  .server-status-indicator {
    font-size: 0.7rem;
    padding: 5px 12px;
    max-width: 200px;
  }
}

