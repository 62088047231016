.market-container {
  width: 100%;
  height: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the contents horizontally */
  gap: 20px;
  z-index: 1;
  position: relative;
  overflow-y: auto;
}

/* Style the title */
.drops-title {
  font-size: 1.8rem;
  color: #F5130D; /* Red color matching the theme */
  text-align: center;
  font-family: 'Press Start 2P', cursive;
  animation: fadeInDown 1s ease-in-out;
  text-shadow: 2px 2px 8px #000;
  margin: 30px;
}

/* Drop card styling */
.drop-card {
  background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.6), inset 0 0 10px rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid #F5130D;
  z-index: 2;
}

.drop-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.7);
}

.drop-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.drop-image {
  max-width: 100%;
  max-height: 200px;
  width: auto;
  height: auto;
  object-fit: contain;
  object-position: center;
  border-radius: 8px;
}

.drop-info {
  margin-top: 10px;
  z-index: 3;
}

.drop-title {
  font-size: 10px;
  color: #fff;
  text-shadow: 2px 2px 8px #000;
  margin: 10px 0;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.6;
  margin-bottom: 10px;
}

.drop-price {
  font-size: 1rem;
  color: #fabf1f;
  margin: 25px 0 10px 0;
}

.buy-button {
  margin-top: 10px;
  padding: 12px 24px;
  background-color: #F5130D;
  color: #fff;
  text-decoration: none;
  border-radius: 8px;
  display: inline-block;
  transition: background-color 0.2s, transform 0.2s;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  z-index: 4;
  position: relative;
}

.buy-button:hover {
  background-color: #ff7676;
  transform: translateY(-3px);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.7);
}

/* Adjust drops-grid to center items if they are few */
.drops-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
  width: 100%;
  margin-top: 10px;
  /*justify-items: center; /* Center items when there are few */
  margin-bottom: 50px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5em;
  color: #ffffff;
  text-align: center;
}

.error {
  text-align: center;
  color: red;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .drop-card {
    padding: 15px;
  }

  .drop-image {
    max-height: 150px;
  }
}
