/* Overlay */
.profile-image-selector-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

/* Modal */
.profile-image-selector-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1a1a;
  padding: 20px;
  border: 2px solid #F5130D;
  border-radius: 5px;
  z-index: 1000;
  max-width: 80%;
  max-height: 70%;
  overflow-y: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}

.profile-image-selector-modal::-webkit-scrollbar {
  width: 14px; /* Ajusta a largura da barra de rolagem */
}

.profile-image-selector-modal::-webkit-scrollbar-track {
  background: #333; /* Cor do fundo da barra de rolagem */
}

.profile-image-selector-modal::-webkit-scrollbar-thumb {
  background-color: #F5130D; /* Cor da barra de rolagem */
  border-radius: 0px; /* Arredondamento da barra */
  border: 2px solid #1a1a1a; /* Borda da barra de rolagem */
}

/* Header */
.profile-image-selector-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.profile-image-selector-header h2 {
  color: white;
  font-family: 'Press Start 2P', sans-serif;
  font-size: 20px;
  margin: 0;
}

.profile-image-selector-close-button {
  background-color: #F5130D;
  color: white;
  border: 2px solid #c0392b; /* Border for more definition */
  padding: 10px;
  border-radius: 10px; /* Rounded corners */
  cursor: pointer;
  font-family: 'Press Start 2P', sans-serif;
  font-size: 14px;
  box-shadow: 0 4px #7d240f;
  transition: all 0.3s ease;
}

.profile-image-selector-close-button:hover {
  background-color: #ff6161;
  box-shadow: 0 6px #c0392b;
  transform: scale(1.05);
}

.profile-image-selector-close-button:active {
  box-shadow: 0 2px #d14727;
  transform: translateY(2px);
}

/* Grid */
.profile-image-selector-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the items */
  gap: 20px; /* Add some space between items */
}

.profile-image-selector-item {
  background-color: #333;
  border: 2px solid #F5130D;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  width: 80px; /* Smaller width */
  height: 80px; /* Smaller height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-image-selector-item.selected {
  box-shadow: 0 0 10px rgba(255, 65, 65, 0.7);
}

.profile-image-selector-item:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(255, 65, 65, 0.7);
}

.profile-image-selector-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
}

/* Select Button */
.select-button {
  margin-top: 20px;
  background-color: #F5130D;
  padding: 10px;
  color: white;
  border-radius: 10px; /* Rounded corners */
  cursor: pointer;
  font-family: 'Press Start 2P', sans-serif;
  font-size: 14px;
  width: 100%;
  border: 2px solid #c0392b; /* Border for more definition */
  box-shadow: 0 4px #7d240f;
  transition: all 0.3s ease;
}

.select-button:hover {
  background-color: #ff6161;
  box-shadow: 0 6px #c0392b;
  transform: scale(1.05);
}

.select-button:active {
  box-shadow: 0 2px #d14727;
  transform: translateY(2px);
}

.select-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


/* Estilos responsivos para dispositivos móveis */
@media (max-width: 768px) {
  /* Ajuste o modal para se adaptar à tela menor */
  .profile-image-selector-modal {
    width: 90%; /* Reduz a largura do modal */
    max-width: 90%; /* Limita a largura máxima */
    max-height: 80%; /* Limita a altura máxima */
    padding: 10px; /* Reduz o padding */
  }

  /* Ajusta o título do modal */
  .profile-image-selector-header h2 {
    font-size: 16px; /* Reduz o tamanho da fonte */
  }

  /* Ajuste o botão de fechar */
  .profile-image-selector-close-button {
    padding: 8px; /* Reduz o padding */
    font-size: 12px; /* Reduz o tamanho da fonte */
  }

  /* Ajuste a grade de seleção de imagens */
  .profile-image-selector-grid {
    gap: 10px; /* Reduz o espaçamento entre os itens */
  }

  /* Ajuste os itens de seleção de imagens */
  .profile-image-selector-item {
    width: 60px; /* Reduz a largura */
    height: 60px; /* Reduz a altura */
  }

  /* Ajuste o botão de seleção */
  .select-button {
    font-size: 12px; /* Reduz o tamanho da fonte */
    padding: 8px; /* Reduz o padding */
  }
}
