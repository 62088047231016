.about-page {
  padding: 30px;
  max-width: 900px;
  margin: 40px auto;
  background: linear-gradient(135deg, #1e1e1e, #282828);
  color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  /*min-height: 1000px;*/
}

.about-header {
  text-align: center;
  margin-bottom: 25px;
}

.about-header h1 {
  font-size: 2.2rem;
  color: #f5130d;
  margin-bottom: 8px;
}

.about-header p {
  font-size: 1.1rem;
  color: #d3d3d3;
}

.about-section {
  margin-bottom: 25px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-section:hover {
  transform: scale(1.03);
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.4);
}

.about-section h2 {
  font-size: 1.6rem;
  margin: 0;
  padding: 12px;
  background: linear-gradient(135deg, #333333, #444444);
  border-radius: 6px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.about-section h2 .section-icon {
  margin-right: 8px;
  color: #f5130d;
}

.about-section h2:hover {
  background-color: #555555;
}

.section-content {
  padding: 15px;
  background-color: #2c2c2c;
  border-radius: 8px;
  margin-top: 12px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.4);
  max-height: 500px;
  overflow: auto;
}

.section-content p,
.section-content ul {
  font-size: 0.95rem;
  line-height: 1.6;
  color: #e0e0e0;
}

.section-content ul {
  list-style-type: disc;
  margin-left: 20px;
}

.about-page-fixed {
  min-height: 1000px;
}

@media (max-width: 1024px) {
  .about-page {
    margin: 25px 15px;
    max-width: 90%;
  }

  .about-section h2 {
    font-size: 1.4rem;
  }

  .section-content p,
  .section-content ul {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .about-page {
    padding: 15px;
    margin: 15px 10px;
    max-width: 100%;
  }

  .about-header h1 {
    font-size: 1.8rem;
  }

  .about-header p {
    font-size: 0.95rem;
  }

  .about-section h2 {
    font-size: 1.3rem;
    padding: 10px;
  }

  .section-content {
    padding: 12px;
    max-height: 400px;
  }

  .section-content p,
  .section-content ul {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .about-page {
    padding: 10px;
    margin: 10px 5px;
  }

  .about-header h1 {
    font-size: 1.6rem;
  }

  .about-header p {
    font-size: 0.85rem;
  }

  .about-section h2 {
    font-size: 1.2rem;
    padding: 8px;
  }

  .section-content {
    padding: 10px;
    max-height: 300px;
  }

  .section-content p,
  .section-content ul {
    font-size: 0.8rem;
  }

  .section-content ul {
    margin-left: 15px;
  }
}