/* Fonte customizada */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.maps-section-container {
  position: relative;
  /*width: 790px;
  height: 135px;
  margin: 0 auto;*/
}

/* Existing CSS for maps section */
.maps-section {
  width: 100%;
  max-width: 790px;
  margin: 0 auto;
  height: 245px;
  padding: 42px 65px;
  box-sizing: border-box;
  position: relative;
  background: linear-gradient(145deg, #242424, #1a1a1a);
  border: 2px solid #F5130D;
  border-radius: 15px;
  color: #fff;
  font-family: 'Press Start 2P', sans-serif;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Overlay sutil para suavizar o fundo */
.maps-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(245, 19, 13, 0.1);
  pointer-events: none;
  z-index: 1;
}

/* Ensure elements inside .maps-section are above the ::before */
.maps-title,
.map-carousel,
.map,
.no-maps-placeholder,
.map-details,
.selected-map,
.nav-button,
.remove-map-button,
.expired-maps-section,
.expired-maps-grid,
.rewards,
.slot,
.controls,
.map-status-container {
  position: relative;
  z-index: 2; /* Ensure these elements are above the gradient overlay */
}

.maps-title {
  margin-top: 10px;
  font-family: 'Press Start 2P', sans-serif;
  font-size: 20px;
  color: #F5130D;
  margin-bottom: 10px;
  text-transform: uppercase;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.map-carousel {
  position: relative;
  z-index: 2;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  transition: transform 0.4s ease;
}

.map {
  flex-shrink: 0;
  width: 200px;
  height: 126px;
  background: #1a1a1a;
  border: 2px solid #F5130D;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.map:hover {
  transform: translateY(-8px);
  box-shadow: 0 6px 18px rgba(245, 19, 13, 0.5);
  z-index: 3;
}

.map-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.map-name {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 6px;
  background: rgba(0, 0, 0, 0.7);
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  transform: translateY(100%);
  transition: transform 0.4s ease;
}

.map:hover .map-name {
  transform: translateY(0);
}

.map-timer {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 4px 8px;
  background: rgba(245, 19, 13, 0.9);
  border-radius: 8px;
  font-size: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.no-maps-placeholder {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #444;
  color: #bbb;
  border: 2px dashed #666;
  border-radius: 10px;
}

.map-details {
  text-align: center;
  margin-bottom: 20px;
  background: linear-gradient(145deg, #1a1a1a, #333);
  padding: 20px;
  border-radius: 10px;
  color: white;
  font-family: 'Press Start 2P', sans-serif;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.selected-map {
  border: 3px solid #F5130D;
  box-shadow: 0 0 20px #F5130D;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  position: relative;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(245, 19, 13, 0.9);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 3;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.nav-button:hover {
  transform: translateY(-50%) scale(1.1);
  box-shadow: 0 4px 12px rgba(245, 19, 13, 0.6);
}

.nav-button.prev {
  left: 10px;
}

.nav-button.next {
  right: 10px;
}

.remove-map-button {
  background: transparent;
  border: none;
  color: red;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 18px;
}

.expired-maps-section {
  margin-top: 20px;
  padding: 20px;
  background: #3E3D3D;
  border-radius: 10px;
  color: white;
}

.expired-maps-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.rewards {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.slot {
  width: 60px;
  height: 60px;
  background-color: #444;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #555;
  margin: 0 5px;
  border-radius: 10px;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.map-status-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.map-status {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 5px;
}

/* Estilo para o indicador de página */
.page-indicator {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  padding: 6px 12px;
  border-radius: 15px;
  font-size: 10px;
  border: 1px solid rgba(245, 19, 13, 0.5);
  z-index: 3;
}

/* Media Queries */
@media (max-width: 768px) {
  .maps-section {
    height: auto;
    padding: 20px;
  }
  .map-carousel {
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
  .map {
    width: calc(50% - 15px);
    min-width: 160px;
    height: 120px;
  }
  .map-name {
    font-size: 9px;
    padding: 4px;
  }
}

@media (max-width: 480px) {
  .maps-section {
    padding: 50px 60px;
  }
  .map {
    width: 100%;
    max-width: 280px;
    height: 140px;
  }
  .page-indicator {
    font-size: 9px;
    padding: 4px 8px;
  }
}
