/* Overlay */
.ranking-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Fundo escuro com opacidade */
  z-index: 999; /* Abaixo do modal, mas acima de outros elementos */
}

/* Estilos para o RankingModal */
.ranking-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*width: 80%;*/
  max-width: 900px;
  max-height: 80%;
  overflow-y: auto;
  background-color: #1a1a1a;
  border: 2px solid #F5130D;
  z-index: 1000;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  color: white;
  font-family: 'Press Start 2P', sans-serif;
  text-align: center;
}

.ranking-modal-content {
  background-color: transparent;
  margin: 0 auto;
  padding: 0;
  border: none;
  width: 100%;
  max-width: 100%;
  text-align: center;
  border-radius: 5px;
  max-height: 80vh;
}

.ranking-modal-content ul {
  list-style-type: none;
  padding: 20px;
  display: inline;
}

.ranking-item {
  background: #292929;
  color: white;
  margin: 58px 100px;
  margin-top: 10px;
  padding: 20px;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /*border: 2px solid #F5130D; /* Borda vermelha para NFTs */
}

.ranking-item:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.7);
}

.ranking-index {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #ffffff;
  text-shadow: 1px 1px 2px black;
}

.ranking-info {
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  margin-top: 10px;
}

.ranking-slot {
  background-color: #1e1e1e;
  padding: 15px;
  border-radius: 5px;
  margin: 5px;
  flex: 1;
  /*min-width: 100px;*/
  text-align: center;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  /*box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.3);*/
  border: 1px solid #F5130D; /* Borda vermelha para NFTs */
}

.ranking-item.first-place {
  border: 2px solid #ffd700;
  background-color: #383838;
}

.ranking-item.active-user {
  border: 2px solid #ffcc00;
  background-color: #444444;
  box-shadow: 0 0 10px #ffcc00;
}

.ranking-title {
  font-size: 24px;
  color: #F5130D;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
  letter-spacing: 2px;
  background-color: #1a1a1a;
  border: 2px solid #F5130D;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 20px;
}

.season-timer {
  font-size: 18px;
  color: #ffd700;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  background-color: #1a1a1a;
  border: 2px solid #ffd700;
  padding: 8px 15px;
  border-radius: 5px;
  display: inline-block;
  margin-bottom: 40px;
  text-align: center;
  margin-left: 20px;
  margin-top: 1px;
}

/* Seção de Recompensas */
.rewards-section {
  margin-top: 20px;
  background: #1e1e1e;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  /*box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.3);*/
  border: 1px solid #F5130D; /* Borda vermelha para NFTs */
}

.rewards-title {
  font-size: 16px;
  color: #ffd700;
  margin-bottom: 10px;
  text-shadow: 1px 1px 2px black;
  margin-top: 5px;
}

.reward-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.reward-coins {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444;
  padding: 10px;
  border-radius: 5px;
  color: #ffd700;
  font-weight: bold;
  font-size: 12px;
  margin-top: 10px;
}

.ranking-nfts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.nft-slot-ranking {
  position: relative;
  width: 60px; 
  height: 60px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #F5130D;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease;
}

.nft-slot-ranking:hover {
  transform: scale(1.05); 
}

.nft-image-ranking {
  width: 100%; /* Take full width of the container */
  height: 100%; /* Take full height of the container */
  object-fit: contain; /* Adapt the image to fit within the slot */
  border-radius: 5px;
}

.nft-name-ranking {
  font-size: 6px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: white;
}

.nft-amount-ranking {
  position: absolute;
  top: -4px;
  right: -10px;
  background-color: #444; /* Bright yellow for better contrast */
  color: #fcfcfc; /* White text for readability */
  border-radius: 50%; /* Circular badge */
  padding: 1px; /* Balanced padding */
  font-size: 8px; /* Slightly larger font for visibility */
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px; /* Minimum width to handle larger numbers */
  height: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  border: 2px solid #F5130D; /* Border to separate from background */
  z-index: 0; /* Ensure it's on top */
}

.close-ranking {
  background-color: #F5130D;
  color: white;
  border: 2px solid #c0392b; /* Add border for more definition */
  padding: 10px;
  cursor: pointer;
  border-radius: 5px; /* Match the border radius of the button style */
  font-size: 14px;
  position: absolute;
  top: 10px;
  right: 10px;
  box-shadow: 0 4px #7d240f; /* Shadow effect */
  transition: all 0.3s ease; /* Smooth transition */
  font-family: 'Press Start 2P', sans-serif; /* Ensure consistent font */
}

.close-ranking:hover {
  background-color: #ff6161;
  box-shadow: 0 6px #c0392b; /* Enhance shadow on hover */
  transform: scale(1.05); /* Slightly enlarge on hover */
}

.close-ranking:active {
  box-shadow: 0 2px #d14727; /* Reduce shadow on active */
  transform: translateY(2px); /* Simulate button press */
}


/* Tooltip for NFT attributes */
.custom-nft-tooltip {
  position: absolute;
  bottom: 110%;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  background: rgba(0, 0, 0, 0.9);
  color: white;
  border: 1px solid #F5130D;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Prevent mouse interaction */
}

.nft-slot-ranking:hover .custom-nft-tooltip {
  opacity: 1; /* Show tooltip on hover */
  pointer-events: auto;
}

.custom-nft-tooltip-header {
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px;
  color: #F5130D;
}

.custom-nft-tooltip-list {
  list-style: none;
  padding: 10px;
  margin: 0;
}

.custom-nft-tooltip-item {
  font-size: 9px;
  text-align: left;
  margin-bottom: 3px;
  line-height: 1.4; /* Space between lines */
}

.custom-nft-tooltip-item .attribute-name {
  color: #F5130D; /* Red color for attribute names */
}

.custom-nft-tooltip-item .attribute-value {
  color: #ffffff; /* White color for attribute values */
}

.ranking-item.active-user {
  border: 2px solid #F5130D;
  background-color: #444444;
  box-shadow: 0 0 20px #F5130D;
}

.ranking-modal::-webkit-scrollbar {
  width: 16px; /* Ajusta a largura da barra de rolagem */
}

.ranking-modal::-webkit-scrollbar-track {
  background: #333; /* Cor do fundo da barra de rolagem */
}

.ranking-modal::-webkit-scrollbar-thumb {
  background-color: #F5130D; /* Cor da barra de rolagem */
  border-radius: 0px; /* Arredondamento da barra */
  border: 2px solid #1a1a1a; /* Borda da barra de rolagem */
}


/* Responsividade */
@media (max-width: 768px) {
  .ranking-modal-content {
      width: 95%;
      padding: 15px;
  }

  .ranking-item {
      margin: 15px 20px;
      padding: 15px;
      font-size: 14px;
  }

  .ranking-info {
      flex-direction: column;
  }

  .ranking-slot {
      min-width: 80px;
      font-size: 12px;
      padding: 10px;
  }

  .ranking-title {
      font-size: 24px;
      padding: 8px 15px;
      white-space: normal; /* Permite a quebra de linha */
      line-height: 1.2; /* Ajusta o espaçamento entre as linhas */
  }

  .season-timer {
      font-size: 15px;
      padding: 6px 12px;
      margin-left: 0px;
      line-height: 1.5;
      margin-bottom: 3px;
  }
}

.rewards-title {
font-size: 12px;
}


@media (max-width: 480px) {
  .ranking-modal-content {
      padding: 10px;
      margin: 10% auto;
  }

  .ranking-item {
      margin: 35px 10px;
      padding: 10px;
      font-size: 12px;
  }

  .ranking-index {
    font-size: 10px;
   
}

  .ranking-slot {
      min-width: 60px;
      font-size: 10px;
      padding: 8px;
  }

  .ranking-title {
      font-size: 20px;
      padding: 6px 10px;
      white-space: normal; /* Permite a quebra de linha */
  line-height: 1.2; /* Ajusta o espaçamento entre as linhas */
  }

  .custom-nft-tooltip-item {
    font-size: 7px;
  }

  .nft-slot-ranking {
    width: 40px;
    height: 40px;
  }

  .nft-amount-ranking {
    font-size: 6px;
    padding: 0px;
    min-width: 19px;
    height: 19px;
  }

  .custom-nft-tooltip {
    width: 170px;
  }

  .season-timer {
      font-size: 14px;
      padding: 5px 10px;
  }

  .ranking-coins {
      font-size: 14px;
  }

  .total-coins {
      font-size: 16px;
  }
}
