.report-container {
  min-height: calc(100vh - 150px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  /*background: linear-gradient(145deg, #111, #1c1c1c);*/
  margin-top: -20px;
}

.report-content {
  width: 100%;
  max-width: 600px;
  background-color: #111;
  border: 2px solid #F5130D;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 0 20px rgba(245, 19, 13, 0.2);
  margin: 20px auto;
}

.report-content h2 {
  color: #fff;
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-family: 'Press Start 2P', cursive;
}

.report-info {
  color: #ccc;
  text-align: center;
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 1.6;
}

.report-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.char-count {
  color: #999;
  font-size: 12px;
}

.form-group input,
.form-group textarea {
  padding: 12px;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #1a1a1a;
  color: #fff;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #F5130D;
  box-shadow: 0 0 5px rgba(245, 19, 13, 0.3);
}

.form-group textarea {
  resize: vertical;
  min-height: 150px;
}

.submit-button {
  padding: 12px 24px;
  background-color: #F5130D;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Press Start 2P', cursive;
}

.submit-button:hover:not(:disabled) {
  background-color: #ff2e28;
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(245, 19, 13, 0.3);
}

.submit-button:disabled {
  background-color: #666;
  cursor: not-allowed;
  opacity: 0.7;
}

.message {
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
}

.message.error {
  background-color: rgba(255, 0, 0, 0.1);
  border: 1px solid #ff0000;
  color: #ff0000;
}

.message.success {
  background-color: rgba(0, 255, 0, 0.1);
  border: 1px solid #00ff00;
  color: #00ff00;
}

.report-warning {
  background-color: rgba(255, 255, 0, 0.1);
  border: 1px solid #FFD700;
  color: #FFD700;
  padding: 0.8rem;
  border-radius: 4px;
  margin: 0.8rem 0;
  font-size: 0.75rem;
  line-height: 1.4;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .report-content {
    padding: 20px;
  }

  .report-content h2 {
    font-size: 20px;
  }

  .submit-button {
    font-size: 14px;
    padding: 10px 20px;
  }
} 