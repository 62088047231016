/* src/components/Header.css */

.menu {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 22px 30px;
  background-color: #111;
  position: relative;
  z-index: 4;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.5);
  border-bottom: 2px solid #F5130D;
  /*overflow: hidden; /* Adicione esta linha */
}

@keyframes pixelFall {
  0% {
    transform: translateY(-100%);
    opacity: 1;
  }
  100% {
    transform: translateY(900%);
    opacity: 0;
  }
}

.pixel {
  position: absolute;
  top: 0;
  width: 8px;
  height: 8px;
  background: #F5130D;
  animation: pixelFall 10s infinite linear;
  z-index: 1;
  pointer-events: none;
}

.pixel:nth-child(2) {
  left: 20%;
  animation-duration: 4s;
}

.pixel:nth-child(3) {
  left: 40%;
  background: #F5130D;
  animation-duration: 2.5s;
}

.pixel:nth-child(4) {
  left: 60%;
  background: #F5130D;
  animation-duration: 3.5s;
}

.pixel:nth-child(5) {
  left: 80%;
  background: #F5130D;
  animation-duration: 2.8s;
}

.pixel:nth-child(6) {
  left: 90%;
  width: 5px;
  height: 5px;
  background: #F5130D;
  animation-duration: 4s;
}

.menu::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  pointer-events: none;
}

.menu .pixel {
  animation: pixelFall 4s infinite linear;
}

.logo {
  justify-self: start;
  transition: transform 0.3s ease;
  z-index: 1001;
  margin-left: 15px;
}

.logo img {
  width: 45px; /* Adjust width as needed */
  height: auto;
  cursor: pointer;
  scale: 1.3;
}

.logo:hover {
  transform: scale(1.5);
}

.nav-center ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  gap: 25px;
  z-index: 1001;
}

.nav-center ul li button {
  text-decoration: none;
  color: #fff;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.8em;
  padding: 8px 16px;
  background-color: #F5130D;
  border: 2px solid #ff6161; /* Border for the pixelated effect */
  border-radius: 5px; /* No rounded corners for a retro look */
  /*box-shadow: 0 0 15px rgba(255, 65, 65, 0.5), inset 0 0 0 0 #ff7676;*/
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  z-index: 1001;
  /* Adding pixelated border effect */
  background-image: repeating-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2) 1px,
    rgba(0, 0, 0, 0) 1px,
    rgba(0, 0, 0, 0) 4px
  );
}

.nav-center ul li button:hover,
.nav-center ul li button.active {
  background-color: #ff7676;
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(255, 65, 65, 0.7), inset 0 0 15px #F5130D;
}

.nav-center ul li button:active {
  transform: scale(0.98);
  box-shadow: 0 0 10px rgba(255, 65, 65, 0.9), inset 0 0 20px #d14727;
}

.nav-center ul li button::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border: 2px solid #F5130D;
  z-index: -1;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.nav-center ul li button:hover::before {
  opacity: 1;
  border-color: #ff6161;
}

.user-info-container {
  display: flex;
  justify-self: end;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  z-index: 1001;
}

.user-info {
  font-size: 11px;
}

.login-button,
.logout-button,
.play-now-button {
  padding: 8px 16px;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.8em;
  color: #fff;
  background-color: #F5130D;
  border: 2px solid #ff6161; /* Border for the pixelated effect */
  border-radius: 5px; /* No rounded corners for a retro look */
  box-shadow: 0 0 15px rgba(255, 65, 65, 0.5), inset 0 0 0 0 #ff7676;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  z-index: 1001;
  /* Adding pixelated border effect */
  background-image: repeating-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2) 1px,
    rgba(0, 0, 0, 0) 1px,
    rgba(0, 0, 0, 0) 4px
  );
}

.login-button:hover,
.logout-button:hover,
.play-now-button:hover {
  background-color: #ff7676;
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(255, 65, 65, 0.7), inset 0 0 15px #F5130D;
}

.login-button:active,
.logout-button:active,
.play-now-button:active {
  transform: scale(0.98);
  box-shadow: 0 0 10px rgba(255, 65, 65, 0.9), inset 0 0 20px #d14727;
}

.login-button::before,
.logout-button::before,
.play-now-button::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  border: 2px solid #F5130D;
  z-index: -1;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.login-button:hover::before,
.logout-button:hover::before,
.play-now-button:hover::before {
  opacity: 1;
  border-color: #ff6161;
}

.play-now-button i,
.logout-button i,
.login-button i {
  font-size: 0.9em;
  margin-right: 4px;
}

.hamburger {
  display: none;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
    font-size: 1.6em;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    justify-self: end;
  }

  .logo img {
    width: 70px; /* Adjust width as needed */
    height: auto;
    cursor: pointer;
    scale: 1.1;
  }

  .user-info {
    font-size: 10px;
    margin-left: 30px;
  }

  .user-info-container {
    justify-self: start;
    order: 1;
  }

  .nav-center ul {
    flex-direction: column;
    gap: 10px;
  }

  .nav-center {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: none;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border-radius: 0 0 10px 10px;
    z-index: 1001;
  }

  .nav-center.open {
    display: flex;
  }

  /* Os botões Play, Login e Logout entram no menu hamburguer */
  .play-now-button,
  .logout-button,
  .login-button {
    width: 100%;
    margin-top: 10px;
  }
}

.report-button {
  background-color: #F5130D;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Press Start 2P', cursive;
  font-size: 0.8rem;
  transition: all 0.3s ease;
}

.report-button:hover {
  background-color: #ff2e28;
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(245, 19, 13, 0.3);
}
