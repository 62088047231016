/* src/styles/oficinaModal.css */

/* Overlay dim */
.oficina-overlay {
    position: fixed;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background: rgba(0,0,0,0.7);
    z-index: 999;
  }
  
  /* Modal container */
  .oficina-modal {
    position: fixed;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1a1a1a;
    width: 90%;
    max-width: 1090px;
    max-height: 80%;
    overflow-y: auto;
    border-radius: 6px;
    border: 2px solid #F5130D;
    color: #fff;
    font-family: 'Press Start 2P', sans-serif;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    z-index: 1000;
    padding: 20px;
    display: none; /* hidden by default; shown via .show */
  }
  .oficina-modal.show {
    display: block;
  }
  
  /* Close button (top-right) */
  .oficina-close-button {
    position: absolute;
    top: 10px; right: 10px;
    background-color: #F5130D;
    color: #fff;
    border: 2px solid #c0392b;
    padding: 8px;
    cursor: pointer;
    border-radius: 8px;
    font-size: 13px;
    box-shadow: 0 4px #7d240f;
    transition: all 0.3s ease;
  }
  .oficina-close-button:hover {
    background-color: #ff6161;
    box-shadow: 0 6px #c0392b;
    transform: scale(1.05);
  }
  .oficina-close-button:active {
    box-shadow: 0 2px #d14727;
    transform: translateY(2px);
  }
  
  /* Refresh button (top-right) */
  .oficina-refresh-button {
    position: absolute;
    top: 10px;
    background-color: #F5130D;
    color: #fff;
    border: 2px solid #c0392b;
    border-radius: 8px;
    font-size: 16px;
    box-shadow: 0 4px #7d240f;
    transition: all 0.3s ease;
    width: 40px; height: 40px;
    text-align: center;
    cursor: pointer;
  }
  .oficina-refresh-button:hover {
    background-color: #ff6161;
    box-shadow: 0 6px #c0392b;
    transform: scale(1.05);
  }
  .oficina-refresh-button:active {
    box-shadow: 0 2px #d14727;
    transform: translateY(2px);
  }

  .refresh-button-inline {
    background-color: #F5130D;
    color: #fff;
    border: 2px solid #c0392b;
    border-radius: 6px;
    font-size: 12px;
    box-shadow: 0 4px #7d240f;
    transition: all 0.3s ease;
    width: 40px; height: 30px;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
  }
  .refresh-button-inline:hover {
    background-color: #ff6161;
    box-shadow: 0 6px #c0392b;
    transform: scale(1.05);
  }
  .refresh-button-inline:active {
    box-shadow: 0 2px #d14727;
    transform: translateY(2px);
  }
  
  /* Header */
  .oficina-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #F5130D;
    padding-bottom: 12px;
    margin-bottom: 15px;
  }
  .oficina-header h2 {
    color: #F5130D;
    font-size: 22px;
    margin: 0;
  }
  
  /* Content area */
  .oficina-content {
    width: 100%;
    position: relative;
  }
  
  /* Error / status text */
  .oficina-error {
    color: #ff8383;
    margin-bottom: 10px;
    text-align: center;
    font-size: 14px;
  }
  .oficina-status {
    color: #76ff76;
    margin-bottom: 10px;
    text-align: center;
    font-size: 14px;
  }
  
  /* Container for total cost + button */
  .repair-all-container {
    text-align: center;
    margin-bottom: 14px;
  }
  .total-cost-label {
    font-size: 14px;
    color: #ff6161;
  }
  .total-cost-value {
    margin-left: 5px;
    color: #fff;
  }
  .repair-all-button {
    display: inline-block;
    margin-top: 8px;
    background-color: #F5130D;
    color: #fff;
    border: 2px solid #c0392b;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 8px;
    font-family: 'Press Start 2P', sans-serif;
    font-size: 10px;
    transition: all 0.3s ease;
  }
  .repair-all-button:hover {
    background-color: #ff6161;
    box-shadow: 0 6px #c0392b;
    transform: scale(1.05);
  }
  .repair-all-button:active {
    box-shadow: 0 2px #d14727;
    transform: translateY(2px);
  }
  
  /* Loading spinner */
  .oficina-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 140px;
  }
  
  /* No cars */
  .oficina-no-cars {
    text-align: center;
    font-size: 15px;
    color: #F5130D;
    margin: 20px;
  }
  
  /* Cards container */
  .oficina-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 14px;
  }
  
  /* Single card */
  .oficina-card {
    background-color: #1a1a1a;
    border-radius: 8px;
    border: 2px solid #F5130D;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    transition: all 0.3s ease;
  }
  /* If in race, fade it */
  .oficina-card.in-race {
    opacity: 0.5;
    filter: grayscale(80%);
  }
  
  /* Car image smaller */
  .oficina-card-image {
    width: 100%;
    height: 70px; /* smaller height */
    margin-bottom: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .oficina-card-image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
  
  /* Info area */
  .oficina-card-info {
    font-size: 10px;
    line-height: 14px;
    color: #fff;
  }
  .oficina-card-info strong {
    color: #ff6161;
  }
  
  /* Name + Rarity in one line */
  .oficina-card-name-rarity {
    margin-bottom: 4px;
  }
  .rarity {
    color: #ff6161;
  }
  
  /* ID line */
  .oficina-card-id {
    margin-bottom: 4px;
  }
  
  /* Damage + status line */
  .oficina-card-damage-status {
    margin-bottom: 4px;
  }
  
  /* Repair cost line */
  .oficina-card-cost {
    text-align: center;
    color: #F5130D;
    font-size: 1.1em;
    margin: 10px 0;
    padding: 8px;
    background: rgba(245, 19, 13, 0.1);
    border-radius: 6px;
    border: 1px solid rgba(245, 19, 13, 0.3);
  }
  
  .oficina-card-cost strong {
    margin-right: 5px;
  }
  
  /* "Parts Damaged" toggle */
  .parts-damaged-details {
    background: rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 8px;
    margin: 10px 0;
    border: 1px solid rgba(245, 19, 13, 0.2);
  }
  .parts-damaged-details summary {
    cursor: pointer;
    color: #F5130D;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 5px 0;
  }
  .parts-damaged-details ul {
    margin: 10px 0 0 0;
    padding: 0;
    list-style: none;
  }
  .parts-damaged-details li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid rgba(245, 19, 13, 0.1);
    font-size: 0.9em;
  }
  .parts-damaged-details li:last-child {
    border-bottom: none;
  }
  .parts-damaged-details li strong {
    color: #F5130D;
    background: rgba(245, 19, 13, 0.1);
    padding: 2px 8px;
    border-radius: 4px;
    min-width: 30px;
    text-align: center;
  }
  
  /* If car is in race, show label */
  .oficina-card-inrace {
    font-size: 10px;
    color: #ff8383;
    margin-top: 6px;
    text-align: center;
  }
  
  /* "Repair with PXRS" button */
  .oficina-repair-button {
    background-color: #F5130D;
    color: #fff;
    border: 2px solid #c0392b;
    padding: 5px 8px;
    cursor: pointer;
    border-radius: 8px;
    font-family: 'Press Start 2P', sans-serif;
    font-size: 9px;
    transition: all 0.3s ease;
    display: block;
    margin: 6px auto 0 auto;
    text-align: center;
  }
  .oficina-repair-button:hover {
    background-color: #ff6161;
    box-shadow: 0 6px #c0392b;
    transform: scale(1.05);
  }
  .oficina-repair-button:active {
    box-shadow: 0 2px #d14727;
    transform: translateY(2px);
  }
  
  /* Custom scrollbar */
  .oficina-modal::-webkit-scrollbar {
    width: 9px;
  }
  .oficina-modal::-webkit-scrollbar-track {
    background: #333;
  }
  .oficina-modal::-webkit-scrollbar-thumb {
    background-color: #F5130D;
    border: 2px solid #1a1a1a;
  }
  
  /* Responsiveness */
  @media (max-width: 600px) {
    .oficina-header h2 {
      font-size: 18px;
    }
    .oficina-card-info {
      font-size: 9px;
      line-height: 12px;
    }
    .oficina-card-image {
      height: 60px;
    }
    .oficina-cards-container {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media (max-width: 400px) {
    .oficina-card-image {
      height: 55px;
    }
    .oficina-cards-container {
      grid-template-columns: 1fr;
    }
  }
  
  .send-to-mechanic-btn {
    margin-top: 10px;
    padding: 8px 16px;
    background: #2196F3;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    width: 100%;
  }

  .send-to-mechanic-btn:hover {
    background: #1976D2;
  }

  .send-to-mechanic-btn:disabled {
    background: #666;
    cursor: not-allowed;
  }

  /* Ajuste o layout para acomodar a nova seção */
  .oficina-modal-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 1200px;
    width: 90%;
    max-height: 90vh;
    background: #1a1a1a;
    padding: 20px;
    border-radius: 8px;
    overflow-y: auto;
  }

  .oficina-cars {
    margin-top: 20px;
  }
  
  /* Adicione estes estilos */
  .oficina-card.in-repair {
    opacity: 0.7;
    pointer-events: none;
    position: relative;
    filter: grayscale(70%);
  }

  .oficina-card.in-repair::after {
    content: '🔧 In Repair';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-30deg);
    background: rgba(0, 0, 0, 0.8);
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.2em;
    color: #fff;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .send-to-mechanic-btn {
    margin-top: 10px;
    padding: 10px 16px;
    background: linear-gradient(45deg, #2196F3, #1976D2);
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    width: 100%;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
  }

  .send-to-mechanic-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(33, 150, 243, 0.3);
  }

  .send-to-mechanic-btn:active {
    transform: translateY(0);
  }

  .send-to-mechanic-btn::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.1), transparent);
    transform: rotate(45deg);
    transition: all 0.3s ease;
  }

  .send-to-mechanic-btn:hover::after {
    transform: rotate(45deg) translate(50%, 50%);
  }

  .send-to-mechanic-btn:disabled {
    background: #666;
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  .oficina-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    padding: 20px;
  }

  .oficina-card {
    background-color: #1a1a1a;
    border: 2px solid #F5130D;
    border-radius: 12px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    box-shadow: 0 5px 15px rgba(245, 19, 13, 0.1);
  }

  .oficina-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, transparent, rgba(245, 19, 13, 0.1), transparent);
    transform: translateX(-100%);
    transition: transform 0.6s ease;
  }

  .oficina-card:hover::before {
    transform: translateX(100%);
  }

  .oficina-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(245, 19, 13, 0.2);
  }

  .oficina-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(245, 19, 13, 0.3);
  }

  .oficina-card-damage-status {
    background: rgba(245, 19, 13, 0.1);
    padding: 8px;
    border-radius: 6px;
    margin: 10px 0;
    font-size: 0.9em;
  }

  .parts-damaged-details {
    background: rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 8px;
    margin: 10px 0;
  }

  .parts-damaged-details summary {
    cursor: pointer;
    color: #F5130D;
    font-weight: bold;
  }

  .send-to-mechanic-btn {
    background: linear-gradient(45deg, #F5130D, #ff4444);
    color: white;
    border: none;
    padding: 10px;
    width: 100%;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .send-to-mechanic-btn:hover:not(:disabled) {
    background: linear-gradient(45deg, #ff4444, #F5130D);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(245, 19, 13, 0.3);
  }

  .send-to-mechanic-btn:disabled {
    background: #666;
    cursor: not-allowed;
    opacity: 0.7;
  }

  /* Animação de entrada dos cards */
  @keyframes cardEntrance {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .oficina-card {
    animation: cardEntrance 0.3s ease forwards;
  }
  