/* src/components/Layout.css */

.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Garante que o layout ocupe toda a altura da tela */
  width: 100%; /* Garante que o layout ocupe toda a largura da tela */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative; /* Adicionado para garantir que os elementos filhos, como o menu, possam ser posicionados corretamente */
}

.footer {
  margin-top: auto;
}
  
.content-container {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #141414;
  position: relative;
  overflow: auto;
  box-sizing: border-box;
}

/* Base background with pixelated racing stripes */
.content-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    linear-gradient(90deg, #1f1f1f 25%, transparent 25%, transparent 75%, #1f1f1f 75%), /* Vertical stripes */
    linear-gradient(0deg, #1f1f1f 25%, transparent 25%, transparent 75%, #1f1f1f 75%); /* Horizontal stripes */
  background-size: 16px 16px;
  opacity: 0.2; /* Subtle effect */
  z-index: 1;
}

.content-container > * {
  position: relative;
  z-index: 3; /* Ensures the content is above the background */
}

.Toastify__toast-container {
  z-index: 9999; /* Certifique-se de que este número seja maior que o z-index do header */
}

.html::-webkit-scrollbar {
  width: 16px; /* Ajusta a largura da barra de rolagem */
}

.html::-webkit-scrollbar-track {
  background: #333; /* Cor do fundo da barra de rolagem */
}

.html::-webkit-scrollbar-thumb {
  background-color: #F5130D; /* Cor da barra de rolagem */
  border-radius: 0px; /* Arredondamento da barra */
  border: 2px solid #1a1a1a; /* Borda da barra de rolagem */
}