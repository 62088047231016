/* server/styles/carDisplay.css */

@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

:root {
  --main-color: #F5130D;
  --bg-dark: #1a1a1a;
  --bg-darker: #121212;
  --text-font: 'Press Start 2P', sans-serif;
}

/* Main Container */
.car-display {
  position: relative;
  width: 100%;
  max-width: 790px;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
  border: 2px solid var(--main-color);
  border-radius: 10px;
  color: #fff;
  font-family: var(--text-font);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  background: linear-gradient(145deg, #1a1a1a, #333);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow: visible; 
}

.car-display::before {
  content: '';
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  /* Removendo o background com padrão */
  opacity: 0.15;
  pointer-events: none;
  z-index: 1;
}

/* Ensure elements inside .car-display are above the ::before */
.car-placeholder,
.items-area,
.equipped-nfts,
.auto-build-buttons,
.multi-cars-container,
.build-panel,
.clear-all-button {
  position: relative;
  z-index: 2;
}

/* Car Placeholder */
.car-placeholder {
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  width: 100%;
  max-width: 600px;
  border-radius: 15px;
  overflow: hidden;
}

.car-placeholder:hover {
  transform: scale(1.05);
}

.car-image {
  width: 100%;
  height: 100%;
  margin: 25px 0 22px 0;
  object-fit: cover;
}

/* Overlay "Select Car" */
.select-car-overlay {
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0,0,0,0.7);
  color: var(--main-color);
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  z-index: 3;
}

/* Items Area */
.items-area {
  position: absolute;
  top: 20px; left: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 3;
}

/* Equipped NFTs */
.equipped-nfts {
  display: flex;
  gap: 10px;
  align-items: center;
}

.equipped-nft {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: #1a1a1a;
  border: 2px solid var(--main-color);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.equipped-nft img {
  width: 100%; height: 100%;
  object-fit: contain;
  border-radius: 5px;
  margin-bottom: 0px;
}

.remove-nft-button {
  position: absolute;
  top: -5px; right: -5px;
  background: var(--main-color);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 16px; height: 16px;
  font-size: 10px;
  font-family: var(--text-font);
  cursor: pointer;
  display: flex; justify-content: center; align-items: center;
  line-height: 1;
  z-index: 4;
}

.empty-slot {
  width: 100%; height: 100%;
  display: flex; justify-content: center; align-items: center;
  font-size: 16px;
  color: var(--main-color);
  background: #1a1a1a;
  border-radius: 5px;
}

/* Items Buttons */
.items-button {
  background-color: #F5130D;
  color: #fff;
  border: 2px solid #c0392b;
  border-radius: 10px;
  width: 60px; height: 60px;
  display: flex; justify-content: center; align-items: center;
  cursor: pointer;
  font-family: var(--text-font);
  font-size: 14px;
  text-transform: uppercase;
  position: relative;
  box-shadow: 0 4px #7d240f;
  transition: all 0.3s ease;
  z-index: 3;
}

.items-button.large-text .items-text {
  font-size: 7.5px;
}

.items-button:hover {
  background-color: #ff6161;
  box-shadow: 0 6px #c0392b;
  transform: scale(1.05);
}

.items-button:active {
  box-shadow: 0 2px #d14727;
  transform: translateY(2px);
}

.items-button:before {
  content: '';
  position: absolute;
  top:0;left:0;right:0;bottom:0;
  background: linear-gradient(145deg, rgba(220,49,49,0.1), rgba(183,45,45,0.3));
  border-radius: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.items-button:hover:before {
  opacity: 1;
}

/* Auto, Build, Check Buttons */
.auto-build-buttons {
  position: absolute;
  top: 20px; right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 10;
}

.auto-button,
.build-button {
  background-color: var(--main-color);
  color: #fff;
  border: 2px solid #c0392b;
  border-radius: 10px;
  width: 50px; height: 35px;
  font-size: 7px;
  font-family: var(--text-font);
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  box-shadow: 0 4px #7d240f;
  transition: all 0.3s ease;
}

.clear-all-button {
  background-color: var(--main-color);
  color: #fff;
  border: 2px solid #c0392b;
  border-radius: 10px;
  height: 35px;
  font-size: 7px;
  font-family: var(--text-font);
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  box-shadow: 0 4px #7d240f;
  transition: all 0.3s ease;
  z-index: 8;
}

.build-button.green {
  background-color: green;
}

.auto-button:hover,
.build-button:hover,
.clear-all-button:hover {
  background-color: #ff6161;
  box-shadow: 0 6px #c0392b;
  transform: scale(1.05);
}

.auto-button:active,
.build-button:active,
.clear-all-button:active {
  box-shadow: 0 2px #d14727;
  transform: translateY(2px);
}

/* Clear All Button Positioning */
.clear-all-button {
  position: absolute;
  bottom: 10px; right: 10px;
  background-color: var(--main-color);
  border-radius: 5px;
  font-size: 7px;
  padding: 5px 10px;
}

.clear-all-button:hover {
  background-color: #ff6161;
}

/* Multiple Cars Container */
.multi-cars-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
}

.car-slot {
  position: relative;
  width: 168px;
  background: #1a1a1a;
  border: 2px solid var(--main-color);
  border-radius: 10px;
  padding: 10px;
  display: flex; flex-direction: column; align-items: center; gap: 10px;
  cursor: pointer;
}

.remove-car-button {
  position: absolute;
  top: 5px; right: 5px;
  background: var(--main-color);
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 20px; height: 20px;
  font-size: 10px;
  font-family: var(--text-font);
  cursor: pointer;
  z-index: 5;
}

.car-image-slot {
  width: 100%;
  height: 100px;
  background: #333;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.car-image-slot img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Car Items */
.car-items {
  display: flex; justify-content: center; gap: 5px; margin-top: 10px;
}

.car-slot .equipped-nft {
  width: 40px;
  height: 40px;
  border: 2px solid var(--main-color);
}

/* Race Status */
.race-status {
  position: absolute;
  top: 5px; left: 5px;
  background: red;
  color: #fff;
  padding: 2px 4px;
  font-size: 10px;
  font-family: var(--text-font);
}

/* Build Panel */
.build-panel {
  position: absolute;
  top: 68px; right: 78px;
  background: var(--bg-darker);
  border: 2px solid var(--main-color);
  padding: 10px;
  border-radius: 5px;
  z-index: 9;
  display: flex; flex-direction: column; gap: 10px;
  width: 230px;
  font-family: var(--text-font);
}

.build-panel input {
  font-family: var(--text-font);
  font-size: 10px;
  background: #333;
  color: #fff;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  margin-top: 28px;
  padding: 5px;
}

.build-panel button {
  background-color: var(--main-color);
  color: #fff;
  border: 2px solid #c0392b;
  border-radius: 5px;
  font-size: 9px;
  font-family: var(--text-font);
  cursor: pointer;
  padding: 5px;
}

.close-build-panel-btn {
  position: absolute;
  top: 5px; right: 5px;
  background: var(--main-color);
  border: none;
  color: #fff;
  cursor: pointer;
  font-family: var(--text-font);
  font-size: 10px;
}

.build-list {
  margin-top: 10px;
  border-top: 1px solid var(--main-color);
  padding-top: 10px;
}

.build-list h4 {
  color: var(--main-color);
  font-size: 10px;
}

.build-list-item {
  display: flex;
  justify-content: space-between;
  font-size: 9px;
  color: #fff;
  margin-bottom: 5px;
}

.build-list-item button {
  margin-left: 5px;
}

/* Loading Spinner Overlay */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(26, 26, 26, 0.8); /* Semi-transparent dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  border-radius: 10px;
}

/* Spinner Animation */
.spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid var(--main-color); /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite;
}

/* Keyframes for Spinner */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive Adjustments */
@media (max-width: 1253px) {
  .car-placeholder {
    width: 720px;
    height: 350px;
  }

  .car-display {
    width: 700px;
    height: 400px;
  }
}

@media (max-width: 768px) {
  .car-placeholder {
    width: 600px;
    height: 290px;
  }

  .select-car-overlay {
    font-size: 14px;
    padding: 8px 16px;
    bottom: 25px;
  }

  .car-display {
    padding: 15px;
  }

  .items-area {
    position: relative;
    top: 0;
    left: 0;
    justify-content: center;
    margin-bottom: 15px;
  }

  .auto-build-buttons {
    position: relative;
    top: 0;
    right: 0;
    flex-direction: row;
    justify-content: center;
    margin-top: 15px;
  }
}

@media (max-width: 480px) {
  .car-placeholder {
    width: 100%;
    height: auto;
    margin-top: 30px;
  }

  .select-car-overlay {
    font-size: 12px;
    padding: 6px 12px;
    bottom: 4px;
  }

  .car-display {
    padding: 10px;
  }

  .items-button.large-text .items-text {
    font-size: 6px;
  }

  .items-button {
    width: 50px;
    height: 50px;
  }

  .items-text {
    font-size: 6.5px;
  }

  .equipped-nft {
    width: 40px;
    height: 40px;
  }

  /* Adjust Spinner Size for Smaller Screens */
  .loading-overlay .spinner {
    width: 40px;
    height: 40px;
    border-width: 6px;
  }
}
