/* Fonte customizada */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    box-sizing: border-box;
}

/* Container principal */
.home-game {
    width: 100%;
    min-height: 100vh;
    background-color: #1a1a1a;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
}

/* Container dos componentes */
.game-area {
    width: 100%;
    max-width: 790px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 0 15px;
    box-sizing: border-box;
}

/* Componentes principais */
.sidebar,
.car-display,
.toggle-attributes-button,
.attributes,
.map-display,
.map-details,
.custom-map-details,
.footer-game {
    width: 100%;
    max-width: 790px;
    margin: 0 auto;
    background: linear-gradient(145deg, #1a1a1a, #333);
    border: 2px solid #F5130D;
    border-radius: 10px;
    box-sizing: border-box;
}

.attributes {
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
}

.attributes.visible {
    opacity: 1;
    max-height: 500px;
    padding: 15px;
    margin-top: 20px;
    visibility: visible;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #000;
}

.loading-text {
    font-size: 2rem;
    color: #fff;
}

/* Ajuste da sidebar */
.sidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 15px;
}

/* Ajuste do footer */
.footer-game {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1a1a1a;
    border: 2px solid #F5130D;
    border-radius: 10px;
}

/* Remover todas as referências ao car-display-container */
.car-display {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 790px;
    background-color: #1a1a1a;
    border: 2px solid #F5130D;
    border-radius: 10px;
}

/* Media Queries */
@media (max-width: 768px) {
    .game-area {
        padding: 0 10px;
    }
    
    .sidebar,
    .car-display,
    .attributes,
    .map-display,
    .map-details,
    .custom-map-details,
    .footer-game {
        width: 100%;
        margin: 10px auto;
    }

    .car-image {
        width: 100%;
        height: auto;
        max-height: 300px;
        object-fit: contain;
    }
}

@media (max-width: 480px) {
    .game-area {
        padding: 0 5px;
    }

    .sidebar,
    .car-display,
    .attributes,
    .map-display,
    .map-details,
    .custom-map-details,
    .footer-game {
        padding: 10px;
        margin: 5px auto;
    }

    .car-image {
        max-height: 200px;
    }

    .sidebar {
        padding: 10px;
        gap: 8px;
    }

    .footer-icons {
        gap: 10px;
    }

    .footer-icon {
        width: 30px;
        height: 30px;
    }

    .footer-icondiscord {
        width: 35px;
        height: 35px;
    }

    .footer-game-text {
        font-size: 0.7em;
        margin-top: 8px;
        padding: 0 5px;
        text-align: center;
    }

    .attributes {
        /*width: 277px;*/
    }

    .toggle-attributes-button {
        font-size: 0.7em;
        padding: 6px;
    }

    .attributes.visible {
        position: relative;
    }

    .attribute {
        width: 95%;
        font-size: 0.7em;
        padding: 6px;
        margin: 5px auto;
    }
}

/* Ajustes para garantir que elementos fiquem em coluna no mobile */
@media (max-width: 768px) {
    .game-area {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sidebar {
        width: 100%;
        max-width: 100%;
        margin-bottom: 10px;
    }

    .map-display {
        margin-bottom: 10px;
    }

    .map-details {
        margin-bottom: 10px;
    }

    /* Ajuste para garantir que não haja scroll horizontal */
    .home-game {
        overflow-x: hidden;
        width: 100%;
    }

    /* Ajuste para o footer */
    .footer-game {
        width: 100%;
    }
}
