@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.start-button {
    background-color: #F5130D;
    color: white;
    border: 2px solid #c0392b; /* Add border for more definition */
    padding: 10px 20px;
    cursor: pointer;
    margin: 20px;
    border-radius: 10px;
    font-size: 23px;
    text-transform: uppercase;
    font-weight: bold;
    box-shadow: 0 4px #7d240f;
    transition: all 0.3s ease;
    font-family: 'Press Start 2P', sans-serif;
    display: block;
    margin-left: auto;
    margin-right: auto;
    animation: pulse 2s infinite; /* Pulsing effect */
}

.start-button:hover {
    animation: none; /* Stop pulsing on hover */
    background-color: #F5130D;
    box-shadow: 0 6px #c0392b;
    transform: scale(1.05);
}

.start-button:active {
    animation: none; /* Stop pulsing when clicked */
    animation: clickEffect 0.5s ease; /* Click animation */
    box-shadow: 0 2px #d14727;
    transform: translateY(2px);
}

/* Pulsing animation */
@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
        box-shadow: 0 0 10px #F5130D, 0 0 20px #F5130D;
    }
    100% {
        transform: scale(1);
    }
}

/* Click animation */
@keyframes clickEffect {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.9);
        box-shadow: 0 0 20px #F5130D, 0 0 30px #F5130D;
    }
    100% {
        transform: scale(1);
    }
}

/* Responsividade */
@media (max-width: 768px) {
    .start-button {
        width: 80%;
        font-size: 14px;
        padding: 8px 16px;
    }
}

@media (max-width: 480px) {
    .start-button {
        width: 82%;
        font-size: 15px;
        padding: 18px 12px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 23px;
    }
}
