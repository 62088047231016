/* Fonte customizada */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.sidebar {
    background: linear-gradient(145deg, #1a1a1a, #333);
    border: 2px solid #F5130D;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    color: white;
    font-family: 'Press Start 2P', sans-serif;
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
    transition: transform 0.3s ease-in-out;
    position: relative;
}

/* Gradient Background Overlay */
.sidebar::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.15;
    pointer-events: none;
    z-index: 1;
}

.sidebar-content,
.icon-button,
.toggle-sidebar-button {
    position: relative;
    z-index: 2; /* Ensure elements are above the gradient overlay */
}

.sidebar.hidden {
    transform: translateX(-100%);
}

.sidebar.visible {
    transform: translateX(0);
}

.sidebar-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding: 10px;
}

.icon-button {
    margin: 0 10px;
    width: 80px;
    height: 80px;
    background-color: #1a1a1a;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    transition: transform 0.2s, box-shadow 0.2s;
}

.icon-button:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.icon-button img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.rewards-button,
.ongoing-races-button {
    background-image: none;
}

.multiplayer-button {
    background-color: #040404;
    cursor: not-allowed;
    opacity: 0.35;
    pointer-events: none;
}

.multiplayer-button:hover {
    background-color: #000000;
    transform: none;
}

.icon-button:hover {
    background-color: #F5130D;
    background-blend-mode: multiply;
}

/* Responsividade */
@media (max-width: 768px) {
    .sidebar {
        max-width: 95%;
        margin: 10px auto;
    }

    .icon-button {
        width: 60px;
        height: 60px;
    }

    .icon-button img {
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 480px) {
    .icon-button {
        width: 50px;
        height: 50px;
        margin: 0 5px;
    }

    .icon-button img {
        width: 30px;
        height: 30px;
    }
}

/* Estilizando o botão de toggle */
.toggle-sidebar-button {
    position: fixed;
    left: 10px;
    top: 25%;
    background-color: #F5130D;
    border: 2px solid #1a1a1a;
    padding: 8px 12px;
    border-radius: 5px;
    color: white;
    font-family: 'Press Start 2P', sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    cursor: pointer;
    z-index: 10;
    display: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5), 
                0 0 10px #F5130D, 
                0 0 15px #F5130D, 
                0 0 20px #F5130D;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.toggle-sidebar-button::before {
    content: '';
    display: inline-block;
    margin-right: 5px;
    transition: transform 0.3s ease;
}

.sidebar.visible + .toggle-sidebar-button::before {
    transform: rotate(180deg);
    content: '';
}

.toggle-sidebar-button:hover {
    background-color: #ff6161;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.7), 
                0 0 15px #ff6161, 
                0 0 20px #ff6161, 
                0 0 25px #ff6161;
    transform: scale(1.05);
}

@media (max-width: 1024px) {
    .toggle-sidebar-button {
        display: block;
    }
}

@media (max-width: 768px) {
    .toggle-sidebar-button {
        padding: 6px 10px;
        font-size: 9px;
        left: 8px;
    }
}

@media (max-width: 480px) {
    .toggle-sidebar-button {
        padding: 5px 8px;
        font-size: 8px;
        left: 5px;
    }
}
