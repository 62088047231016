@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.notification {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(245, 19, 13, 0.8);
  color: white;
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  font-family: 'Press Start 2P', sans-serif;
  font-size: 16px;
  text-align: center;
  max-width: 80%;
  line-height: 1.6; /* Aumenta o espaçamento entre linhas */
}

@media (max-width: 768px) {
  .notification {
    font-size: 14px;
    padding: 15px;
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .notification {
    font-size: 12px;
    padding: 10px;
    max-width: 95%;
  }
}
