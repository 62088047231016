/* Fonte customizada */
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;700&display=swap');

.admin-panel, .template-selector-modal, .maps-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #1a1a1a;
    padding: 20px;
    border: 2px solid #F5130D;
    z-index: 1000;
    width: 80%;
    max-width: 800px;
    max-height: 80%;
    overflow-y: auto;
    border-radius: 10px;
    color: white;
    font-family: 'Rajdhani', sans-serif;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    padding: 40px;
}

.admin-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.admin-panel h2 {
    font-size: 24px;
    color: #ff6b6b;
}

.close-admin-button, .close-modal-button {
    background: transparent;
    border: none;
    color: #ff6b6b;
    font-size: 24px;
    cursor: pointer;
}

.admin-panel div {
    margin-bottom: 10px;
}

.admin-panel label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    color: #ff6b6b;
}

.admin-panel input {
    width: 200px;
    background: #444;
    border: none;
    border-radius: 5px;
    padding: 5px;
    color: white;
    font-family: 'Rajdhani', sans-serif;
}

.admin-panel h3, .admin-panel h4 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: #ff6b6b;
}

.admin-panel .nft-grid, .template-selector-modal .nft-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
    margin-top: 10px;
}

.admin-panel .nft-item, .template-selector-modal .nft-item {
    background-color: #444;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s;
}

.admin-panel .nft-item:hover, .template-selector-modal .nft-item:hover {
    transform: scale(1.05);
}

.admin-panel .nft-image, .template-selector-modal .nft-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 10px;
}

.admin-panel .nft-name, .template-selector-modal .nft-name {
    font-size: 14px;
    text-align: center;
    color: #ff6b6b;
}

.selected-nfts {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.selected-nft-item {
    background-color: #444;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.selected-nft-template {
    font-size: 14px;
    text-align: center;
    color: #ff6b6b;
}

.selected-nft-item button, .admin-panel button, .template-selector-modal button, .map-item button {
    background: #ff6b6b;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 14px;
    transition: background 0.3s ease;
}

.selected-nft-item button {
    margin-top: 10px;
}

.admin-panel button, .template-selector-modal button {
    font-size: 16px;
    padding: 10px 20px;
    margin-top: 10px;
    font-family: 'Rajdhani', sans-serif;
    margin-right: 10px;
}

.admin-panel button:hover, .template-selector-modal button:hover, .map-item button:hover {
    background: #e55b5b;
}

.maps-modal-content, .map-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #444;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
}

.map-item .map-image-small {
    width: 100px;
    height: auto;
    border-radius: 5px;
    margin-right: 10px;
}

.close-modal-button {
    align-self: flex-end;
    margin-bottom: 10px;
}

/* Responsividade */
@media (max-width: 768px) {
    .admin-panel, .template-selector-modal, .maps-modal {
        width: 90%;
        padding: 20px;
    }

    .admin-panel h2, .admin-panel h3, .admin-panel h4 {
        font-size: 20px;
    }

    .admin-panel button, .template-selector-modal button, .map-item button {
        font-size: 14px;
        padding: 8px 15px;
    }

    .admin-panel input {
        width: 100%;
        font-size: 14px;
    }

    .admin-panel .nft-grid, .template-selector-modal .nft-grid {
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    }
}

@media (max-width: 480px) {
    .admin-panel, .template-selector-modal, .maps-modal {
        width: 100%;
        max-width: 100%;
        padding: 15px;
    }

    .admin-panel h2, .admin-panel h3, .admin-panel h4 {
        font-size: 18px;
    }

    .admin-panel button, .template-selector-modal button, .map-item button {
        font-size: 12px;
        padding: 5px 10px;
    }

    .admin-panel .nft-grid, .template-selector-modal .nft-grid {
        grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    }

    .close-admin-button, .close-modal-button {
        font-size: 20px;
    }
}

.mechanic-config-settings {
    padding: 20px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-top: 20px;
}

.mechanic-config-settings h3 {
    color: #F5130D;
    margin-bottom: 20px;
    font-size: 1.2em;
}

.mechanic-type-config {
    background: rgba(0, 0, 0, 0.3);
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.mechanic-type-config h4 {
    color: #fff;
    margin-bottom: 15px;
    font-size: 1em;
}

.config-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 8px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
}

.config-field label {
    color: #fff;
    font-size: 0.8em;
    margin-right: 10px;
}

.config-field input {
    width: 100px;
    padding: 5px;
    background: #333;
    border: 1px solid #F5130D;
    color: #fff;
    border-radius: 4px;
}

.damage-config {
    background: rgba(0, 0, 0, 0.3);
    padding: 15px;
    border-radius: 8px;
    margin-top: 20px;
}

.damage-config h4 {
    color: #fff;
    margin: 15px 0;
    font-size: 1em;
}

.save-config-button {
    background: #F5130D;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
    font-family: 'Press Start 2P', sans-serif;
    font-size: 0.8em;
    transition: all 0.3s ease;
}

.save-config-button:hover {
    background: #ff4444;
    transform: scale(1.05);
}

/* Responsividade */
@media (max-width: 768px) {
    .config-field {
        flex-direction: column;
        align-items: flex-start;
    }

    .config-field input {
        width: 100%;
        margin-top: 5px;
    }

    .mechanic-config-settings h3 {
        font-size: 1em;
    }

    .mechanic-type-config h4 {
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .mechanic-config-settings {
        padding: 10px;
    }

    .mechanic-type-config {
        padding: 10px;
    }

    .config-field {
        padding: 5px;
    }

    .save-config-button {
        width: 100%;
    }
}
