/* Fonte customizada */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

/* Background Scan Effect */
.stake-container {
    width: 100vw; /* 100% da largura da viewport */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    color: #e0e0e0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-sizing: border-box;
}

/* Stake Info */
.stake-info {
    text-align: center;
    margin-bottom: 45px;
    padding: 30px;
    border-radius: 15px;
    background: #232323;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.7), inset 0 0 20px rgba(0, 0, 0, 0.7);
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 400px;
    margin-top: 100px;
    border: 2px solid #F5130D;
}

.stake-info h2 {
    font-size: 14px;
    margin-bottom: 15px;
    color: #e0e0e0;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
    margin-top: 30px;
}

/* Adicionado: Stake Title */
.stake-title {
    font-size: 22px;
    margin-bottom: 20px;
    color: #e0e0e0;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
    font-family: 'Press Start 2P', cursive;
    margin-top: 30px;
}

.separator-line {
    border: none;
    border-top: 2px solid #F5130D;
    margin: 20px 0;
    width: 100%;
    max-width: 400px;
}

/* Adicionado: Info Grid */
.info-grid {
    /*display: flex;*/
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

@media (min-width: 768px) {
    .info-grid {
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
    }
}

/* Adicionado: Highlight */
.highlight {
    color: #ffffff;
    font-size: 12px;
    font-family: 'Press Start 2P', cursive;
    line-height: 1.5; /* 1.5 vezes o tamanho da fonte */
}

/* Adicionado: Your Balance */
.your-balance {
    font-size: 20px;
    margin-bottom: 15px;
    color: #e0e0e0;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
}

/* Adicionado: Highlight Rarities */
.highlight-rarities {
    color: #e0e0e0;
}

.highlight-rarities p {
    font-size: 12px;
    color: #ffffff;
    font-family: 'Press Start 2P', cursive;
    margin-top: 30px;
}

.highlight-rarities ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.highlight-rarities li {
    font-size: 15px;
    color: #f8bd47;
    font-family: 'Press Start 2P', cursive;
    line-height: 1.5; /* 1.5 vezes o tamanho da fonte */
    text-align: left;
}

/* Novo: Stake Rarity Counts */
.stake-rarity-counts {
    margin-top: 20px;
}

.stake-rarity-counts p {
    font-size: 14px;
    color: #ffffff;
    font-family: 'Press Start 2P', cursive;
    margin-bottom: 10px;
}

.rarity-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.rarity-card {
    background: #1a1a1a;
    border: 2px solid #F5130D;
    border-radius: 10px;
    padding: 10px 15px;
    width: 56px;
    text-align: center;
    box-shadow: 0 0 15px rgba(245, 19, 13, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.rarity-card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(245, 19, 13, 0.8);
}

.rarity-name {
    font-size: 7px;
    color: #f8bd47;
    font-family: 'Press Start 2P', cursive;
    margin-bottom: 5px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.rarity-count {
    font-size: 9px;
    color: #ffffff;
    font-family: 'Press Start 2P', cursive;
}

/* Gauge */
.gauge-container {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #232323;
    border: 2px solid #F5130D;
    top: -70px;
    left: calc(50% - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 15px #000000;
    z-index: 0;
}

.gauge {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #161616;
    position: relative;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
}

.gauge::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background: #F5130D;
    border-radius: 50%;
}

.needle {
    position: absolute;
    width: 4px;
    height: 35px;
    background: #F5130D;
    top: 15px;
    left: calc(50% - 2px);
    transform-origin: bottom center;
    transform: rotate(0deg);
    animation: spin 3s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(-45deg); }
    50% { transform: rotate(45deg); }
    100% { transform: rotate(-45deg); }
}

/* Stake Section */
.stake-section {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 1600px;
    margin-top: 40px;
    gap: 100px;
    margin-bottom: 80px;
}

.stake-subsection {
    background: #161616;
    border-radius: 15px;
    padding: 30px;
    text-align: center;
    width: 100%;
    max-width: 800px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    border: 2px solid #F5130D;
}

.stake-subsection h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
    color: #e0e0e0;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
    font-family: 'Press Start 2P', cursive;
}

/* Buttons Group */
.buttons-group {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
}

/* NFT List */
.nft-list-stake {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    max-height: 500px;
    overflow-y: auto;
    padding: 10px;
    border-radius: 15px;
    background-color: #232323;
    border: 2px solid #444;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    height: 500px;
    border: 2px solid #F5130D;
    margin-top: 15px;
}

.nft-item-stake {
    border: 2px solid #444;
    border-radius: 8px;
    width: 120px;
    height: 160px;
    text-align: center;
    cursor: pointer;
    position: relative;
    transition: transform 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
    background-color: #232323;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}

.nft-item-stake.selected {
    background-color: #161616;
    border-color: #F5130D;
}

.nft-item-stake:hover:not(.in-stake) {
    transform: translateY(-5px);
    border-color: #F5130D;
    box-shadow: 0 0 15px rgba(245, 19, 13, 0.8);
}

.nft-image-stake {
    width: 80px;
    height: 80px;
    object-fit: contain;
    border-radius: 4px;
    margin-bottom: 10px;
}

.nft-name-stake {
    margin-bottom: 5px;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

.nft-rarity-stake {
    font-size: 10px;
    color: #ffcc00;
    text-align: center;
    margin-bottom: 5px;
}

.nft-id-stake {
    font-size: 10px;
    color: #ffcc00;
    text-align: center;
    margin-top: auto;
    margin-bottom: 5px;
}

.in-stake {
    opacity: 0.3;
    cursor: not-allowed;
}

.in-stake-text-stake {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #F5130D;
    color: white;
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 10px;
    text-transform: uppercase;
}

/* Botões Gerais */
.custom-collect-button,
.custom-select-all-button,
.custom-refresh-button-stake {
    padding: 12px 25px; /* Alinhado com o .play-button */
    font-size: 0.7em; /* Alinhado com o .play-button */
    background-color: #F5130D;
    color: #fff;
    border: 2px solid #ff6161; /* Alinhado com o .play-button */
    border-radius: 5px; /* Alinhado com o .play-button */
    cursor: pointer;
    position: relative; /* Adicionado para efeito de overflow */
    overflow: hidden; /* Adicionado para efeito de overflow */
    margin: 10px auto;
    font-family: 'Press Start 2P', cursive;
    text-transform: uppercase;
    /*letter-spacing: 2px; /* Alinhado com o .play-button */
    transition: all 0.3s ease;
    background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2) 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0) 4px); /* Alinhado com o .play-button */
}

/* Hover e estado ativo */
.custom-collect-button:hover,
.custom-select-all-button:hover,
.custom-refresh-button-stake:hover {
    background-color: #ff7676;
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(255, 65, 65, 0.7), inset 0 0 15px #F5130D;
}

.custom-collect-button:active,
.custom-select-all-button:active,
.custom-refresh-button-stake:active {
    transform: scale(0.98);
    box-shadow: 0 0 10px rgba(255, 65, 65, 0.9), inset 0 0 20px #d14727;
}

/* Pseudo-elemento antes do botão */
.custom-collect-button::before,
.custom-select-all-button::before,
.custom-refresh-button-stake::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: 2px solid #F5130D;
    z-index: -1;
    opacity: 0.7;
    transition: opacity 0.3s ease;
}

/* Hover no pseudo-elemento */
.custom-collect-button:hover::before,
.custom-select-all-button:hover::before,
.custom-refresh-button-stake:hover::before {
    opacity: 1;
    border-color: #ff6161;
}

/* Estilo aplicado ao custom-stake-button mantendo o posicionamento e tamanho */
.custom-stake-button {
    padding: 12px 25px; /* Estilo do play-button */
    font-size: 0.8em; /* Estilo do play-button */
    background-color: #F5130D;
    color: #fff;
    border: 2px solid #ff6161; /* Estilo do play-button */
    border-radius: 5px; /* Estilo do play-button */
    cursor: pointer;
    position: fixed; /* Mantém o posicionamento original */
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    width: 200px; /* Mantém a largura específica */
    text-transform: uppercase;
    line-height: 1.3;
    font-family: 'Press Start 2P', cursive;
    transition: all 0.3s ease;
    box-shadow: 0 0 15px rgba(255, 65, 65, 0.5), inset 0 0 0 0 #ff7676;
    background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2) 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0) 4px);
    overflow: hidden;
    margin-bottom: 80px;
}

/* Hover e estado ativo */
.custom-stake-button:hover {
    background-color: #ff7676;
    transform: scale(1.05) translateX(-50%);
    box-shadow: 0 0 20px rgba(255, 65, 65, 0.7), inset 0 0 15px #F5130D;
}

.custom-stake-button:active {
    transform: scale(0.98) translateX(-50%);
    box-shadow: 0 0 10px rgba(255, 65, 65, 0.9), inset 0 0 20px #d14727;
}

/* Pseudo-elemento antes do botão */
.custom-stake-button::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: 2px solid #F5130D;
    z-index: -1;
    opacity: 0.7;
    transition: opacity 0.3s ease;
}

/* Hover no pseudo-elemento */
.custom-stake-button:hover::before {
    opacity: 1;
    border-color: #ff6161;
}

/* Estilo aplicado ao custom-remove-stake-button mantendo o posicionamento */
.custom-remove-stake-button {
    padding: 12px 25px; /* Estilo do play-button */
    font-size: 0.8em; /* Estilo do play-button */
    background-color: #F5130D;
    color: #fff;
    border: 2px solid #ff6161; /* Estilo do play-button */
    border-radius: 5px; /* Estilo do play-button */
    cursor: pointer;
    position: fixed; /* Mantém o posicionamento original */
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    width: 200px; /* Mantém a largura específica */
    text-transform: uppercase;
    line-height: 1.3;
    font-family: 'Press Start 2P', cursive;
    transition: all 0.3s ease;
    box-shadow: 0 0 15px rgba(255, 65, 65, 0.5), inset 0 0 0 0 #ff7676;
    background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2) 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0) 4px);
    overflow: hidden;
    margin-bottom: 80px;
}

/* Hover e estado ativo */
.custom-remove-stake-button:hover {
    background-color: #ff7676;
    transform: scale(1.05) translateX(-50%);
    box-shadow: 0 0 20px rgba(255, 65, 65, 0.7), inset 0 0 15px #F5130D;
}

.custom-remove-stake-button:active {
    transform: scale(0.98) translateX(-50%);
    box-shadow: 0 0 10px rgba(255, 65, 65, 0.9), inset 0 0 20px #d14727;
}

/* Pseudo-elemento antes do botão */
.custom-remove-stake-button::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: 2px solid #F5130D;
    z-index: -1;
    opacity: 0.7;
    transition: opacity 0.3s ease;
}

/* Hover no pseudo-elemento */
.custom-remove-stake-button:hover::before {
    opacity: 1;
    border-color: #ff6161;
}

/* Paginação */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-top: 20px;
}

.custom-pagination-button {
    width: 35px;
    height: 35px;
    background-color: #F5130D; /* Cor do fundo alinhada com os outros botões */
    color: #fff; /* Cor do texto */
    border: 2px solid #ff6161; /* Estilo da borda */
    border-radius: 5px; /* Arredondamento alinhado com os outros botões */
    cursor: pointer;
    font-size: 0.8em; /* Ajusta o tamanho da fonte */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease; /* Transição */
    box-shadow: 0 0 15px rgba(255, 65, 65, 0.5), inset 0 0 0 0 #ff7676; /* Sombra */
    background-image: repeating-linear-gradient(45deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2) 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0) 4px); /* Estilo de fundo */
    position: relative; /* Necessário para o pseudo-elemento */
    overflow: hidden; /* Necessário para o pseudo-elemento */
}

/* Hover e estado ativo */
.custom-pagination-button:hover {
    background-color: #ff7676;
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(255, 65, 65, 0.7), inset 0 0 15px #F5130D;
}

.custom-pagination-button:active {
    transform: scale(0.98);
    box-shadow: 0 0 10px rgba(255, 65, 65, 0.9), inset 0 0 20px #d14727;
}

/* Pseudo-elemento antes do botão */
.custom-pagination-button::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border: 2px solid #F5130D;
    z-index: -1;
    opacity: 0.7;
    transition: opacity 0.3s ease;
}

/* Hover no pseudo-elemento */
.custom-pagination-button:hover::before {
    opacity: 1;
    border-color: #ff6161;
}

/* Botão de página ativa */
.custom-pagination-button.active {
    background-color: #F5130D;
    box-shadow: 0 6px 15px rgba(230, 57, 70, 0.5);
}

/* Loading Spinner */
.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.spinner-wrapper {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #232323;
    border-radius: 10px;
}

/* Efeitos adicionais */
.box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    background: linear-gradient(45deg, transparent, rgba(230, 57, 70, 0.2));
    z-index: -1;
    filter: blur(8px);
}

/* Adiciona a classe para "In Race" com fundo azul */
.in-race-text-stake {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #1e90ff; /* Azul para indicar que está em corrida */
    color: white;
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 10px;
    text-transform: uppercase;
}

/* Assegura que a classe .in-race tenha o mesmo comportamento visual */
.nft-item-stake.in-race {
    opacity: 0.5; /* Opacidade reduzida para dar o efeito de desabilitado */
    cursor: not-allowed; /* Impede a interação com o NFT */
}

/* Responsividade */
@media (max-width: 768px) {
    .stake-section {
        flex-direction: column;
        align-items: center;
    }

    .stake-subsection {
        max-width: 100%;
        margin-top: 20px;
    }

    .stake-info {
        margin-top: 70px;
    }

    .gauge-container {
        top: -50px;
        width: 80px;
        height: 80px;
        left: calc(50% - 40px);
    }

    .gauge {
        width: 50px;
        height: 50px;
    }

    .needle {
        height: 25px;
    }
}

@media (max-width: 480px) {
    body {
        padding: 0px;
    }

    .stake-container {
        padding: 20px;
    }

    .gauge-container {
        top: -40px;
        width: 60px;
        height: 60px;
        left: calc(50% - 30px);
    }

    .gauge::after {
        width: 5px;
        height: 5px;
    }

    .gauge {
        width: 40px;
        height: 40px;
    }

    .needle {
        height: 20px;
        width: 3px;
        top: 8px;
    }

    .stake-subsection {
        padding: 15px;
    }

    .stake-info {
        padding: 15px;
    }

    .custom-collect-button,
    .custom-select-all-button,
    .custom-refresh-button-stake,
    .custom-stake-button,
    .custom-remove-stake-button {
        padding: 8px 10px;
        font-size: 12px;
    }

    .slot {
        height: 50px;
        font-size: 10px;
    }

    /* Ajuste nos Cards de Raridade */
    .rarity-cards {
        flex-direction: column;
        align-items: center;
    }

    .rarity-card {
        width: 100%;
        max-width: 200px;
    }
}
