/* client/src/styles/itemSelection.css */

/* Overlay */
.item-selection-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

/* Modal */
.item-selection-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1a1a;
  padding: 20px;
  border: 2px solid #F5130D;
  border-radius: 5px;
  z-index: 1000;
  max-width: 80%;
  max-height: 70%;
  overflow-y: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
}

.item-selection-modal::-webkit-scrollbar {
  width: 14px; /* Ajusta a largura da barra de rolagem */
}

.item-selection-modal::-webkit-scrollbar-track {
  background: #333; /* Cor do fundo da barra de rolagem */
}

.item-selection-modal::-webkit-scrollbar-thumb {
  background-color: #F5130D; /* Cor da barra de rolagem */
  border-radius: 0px; /* Arredondamento da barra */
  border: 2px solid #1a1a1a; /* Borda da barra de rolagem */
}

/* Header */
.item-selection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.item-selection-header h2 {
  color: white;
  font-family: 'Press Start 2P', sans-serif;
  font-size: 20px;
  margin: 0;
}

/* Close Button */
.item-selection-close-button {
  background-color: #F5130D;
  color: white;
  border: 2px solid #c0392b;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Press Start 2P', sans-serif;
  box-shadow: 0 4px #7d240f;
  transition: all 0.3s ease;
  font-size: 14px;
}

.item-selection-close-button:hover {
  background-color: #ff6161;
  box-shadow: 0 6px #c0392b;
  transform: scale(1.05);
}

.item-selection-close-button:active {
  box-shadow: 0 2px #d14727;
  transform: translateY(2px);
}

/* Filtros */
.item-selection-filters {
  display: flex;
  flex-direction: column; /* Empilhar filtros verticalmente */
  padding: 10px 0;
  border-bottom: 1px solid #444;
  margin-bottom: 20px;
}

.filter-section-items {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px; /* Espaço entre as seções de filtro */
  width: 100%; /* Garantir largura completa */
}

.filter-section-items h4 {
  margin-bottom: 5px;
  font-size: 14px;
  color: #F5130D;
}

.rarity-options {
  display: flex;
  flex-wrap: wrap;
  width: 100%; /* Garantir que não exceda o pai */
}

.rarity-label {
  margin-right: 15px;
  margin-bottom: 10px; /* Espaçamento entre linhas */
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 1 1 30%; /* Até três itens por linha, ajustar conforme necessário */
}

.rarity-label input {
  margin-right: 5px;
}

.sort-options {
  display: flex;
  gap: 10px;
}

.sort-options select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #F5130D;
  background-color: #333;
  color: white;
  font-family: 'Press Start 2P', sans-serif;
}

.auto-selection-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.auto-selection-buttons button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background-color: #F5130D;
  color: white;
  cursor: pointer;
  font-family: 'Press Start 2P', sans-serif;
  font-size: 12px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.auto-selection-buttons button:hover {
  background-color: #ff6161;
  transform: scale(1.05);
}

.auto-selection-buttons button:active {
  transform: translateY(2px);
}

/* Grid de Itens */
.item-selection-grid-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.item-selection-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.item-selection-item {
  background-color: #1a1a1a;
  border: 2px solid #F5130D;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  width: 100px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: 'Press Start 2P', sans-serif;
  position: relative;
  overflow: hidden;
}

.item-selection-item.selected {
  box-shadow: 0 0 10px rgba(255, 65, 65, 0.7);
}

.item-selection-item:hover {
  transform: scale(1.05);
}

/* Adicionando estilo para itens em "in-race" ou "in-stake" */
.item-selection-item.in-race,
.item-selection-item.in-stake,
.item-selection-item.disabled {
  opacity: 0.3;
  cursor: not-allowed; /* Indica que o item não pode ser selecionado */
}

/* Faixa diagonal para In Race e In Stake */
.item-selection-item .status-overlay {
  position: absolute;
  top: 15px;
  left: -80px;
  width: 189%;
  height: 18px;
  line-height: 20px;
  text-align: center;
  transform: rotate(-45deg);
  font-size: 6px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  z-index: 1;
  opacity: 1;
}

/* Estilo para In Race */
.item-selection-item .status-overlay.in-race {
  background-color: rgba(39, 61, 255, 0.9);
  border: 2px solid #273dff;
  box-shadow: 0 0 8px rgba(39, 61, 255, 0.5);
}

/* Estilo para In Stake */
.item-selection-item .status-overlay.in-stake {
  background-color: rgba(245, 19, 13, 0.9);
  border: 2px solid #F5130D;
  box-shadow: 0 0 8px rgba(255, 65, 65, 0.5);
}

/* Imagem do NFT */
.item-selection-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
  width: 100%;
  height: 60px;
}

.item-selection-image {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.item-selection-name {
  font-size: 8px;
  text-align: center;
  white-space: nowrap;      /* Impede a quebra de linha */
  overflow: hidden;         /* Oculta o texto que não cabe */
  text-overflow: ellipsis;  /* Adiciona "..." ao final do texto */
  margin-top: 5px;
  max-width: 80px;          /* Define uma largura máxima para caber no slot */
  width: 100%;              /* Assegura que o elemento ocupe toda a largura disponível */
}

.item-chances {
  font-size: 8px;
  text-align: center;
  line-height: 1.2;
  margin-top: 15px;
  color: #f5c60d;
}

/* Carregamento */
.item-selection-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.item-selection-loading-container p {
  color: white;
  font-family: 'Press Start 2P', sans-serif;
  font-size: 18px;
}

/* Botão Equip */
.equip-button {
  margin-top: 20px;
  background-color: #F5130D;
  padding: 10px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Press Start 2P', sans-serif;
  font-size: 14px;
  width: 100%;
  border: 2px solid #c0392b;
  box-shadow: 0 4px #7d240f;
  transition: all 0.3s ease;
}

.equip-button:hover {
  background-color: #ff6161;
  box-shadow: 0 6px #c0392b;
  transform: scale(1.05);
}

.equip-button:active {
  box-shadow: 0 2px #d14727;
  transform: translateY(2px);
}

.equip-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Itens Selecionados */
.selected-items-container {
  margin-top: 20px;
  padding: 10px;
  background-color: #333;
  border-radius: 5px;
  text-align: center;
}

.selected-items-grid {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.selected-item {
  width: 60px;
  height: 60px;
  border: 2px solid #F5130D;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.selected-item-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.selected-item-name {
  display: none;
}

.selected-items-container h3 {
  text-align: center;
  margin-bottom: 10px;
  color: white;
  font-family: 'Press Start 2P', sans-serif;
}

/* Botão de Refresh */
.item-selection-refresh-button {
  background-color: #F5130D;
  color: white;
  border: 2px solid #c0392b;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  font-family: 'Press Start 2P', sans-serif;
  box-shadow: 0 4px #7d240f;
  transition: all 0.3s ease;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 20px;
  position: absolute;
  right: 60px;
  top: 25px;
}

.item-selection-refresh-button:hover {
  background-color: #ff6161;
  box-shadow: 0 6px #c0392b;
  transform: scale(1.05);
}

.item-selection-refresh-button:active {
  box-shadow: 0 2px #d14727;
  transform: translateY(2px);
}

.item-selection-id {
  font-size: 6.5px;
  color: #ffffff;
  text-align: center;
  margin-top: 8px;
}

/* Highlight items from 'pixelracesgo' collection */
.item-selection-item.wax-highlight {
  background-color: #202020;
  border: 1px solid #ffce00;
}

/* Botões de Paginação */
.item-selection-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 5px;
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}

.item-selection-pagination-button {
  background-color: #F5130D;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.item-selection-pagination-button:hover {
  background-color: #ff6161;
}

.item-selection-pagination-button-active {
  background-color: #333;
  color: #F5130D;
}

.collection-select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #F5130D;
  background-color: #333;
  color: white;
  font-family: 'Press Start 2P', sans-serif;
}

/* Responsividade para Tablets */
@media (max-width: 768px) {
  .item-selection-modal {
    width: 90%;
    max-height: 75%;
    padding: 15px;
  }

  .item-selection-filters {
    flex-direction: column;
  }

  .filter-section-items h4 {
    font-size: 12px;
  }

  .rarity-label {
    flex: 1 1 45%; /* Até dois itens por linha */
  }

  .sort-options select {
    font-size: 10px;
    padding: 4px;
  }

  .auto-selection-buttons button {
    font-size: 10px;
    padding: 4px 8px;
  }

  .item-selection-header h2 {
    font-size: 13px;
  }

  .item-selection-id {
    font-size: 5.5px;
    color: #ffffff;
    text-align: center;
    margin-top: 8px;
  }

  .item-selection-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }

  .item-selection-item {
    width: 90px;
    height: 140px;
  }

  .item-selection-name {
    font-size: 9px;
    width: 80px;
  }

  .equip-button {
    padding: 8px;
    font-size: 12px;
  }

  .selected-item {
    width: 50px;
    height: 50px;
  }

  .item-selection-item .status-overlay {
    position: absolute;
    top: 14px;
    left: -74px;
    width: 189%;
    height: 15px;
    line-height: 16px;
    text-align: center;
    transform: rotate(-45deg);
    font-size: 5px;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    z-index: 1;
    opacity: 1;
  }
}

/* Responsividade para Dispositivos Móveis */
@media (max-width: 480px) {
  .item-selection-modal {
    width: 95%;
    max-height: 80%;
    padding: 10px;
  }

  .item-selection-filters {
    flex-direction: column;
  }

  .item-selection-filters .filter-section-items {
    margin-bottom: 10px;
  }

  .item-selection-filter select {
    width: 100%;
  }

  .item-selection-header h2 {
    font-size: 10px;
  }

  .item-selection-refresh-button {
    font-size: 10px;
    right: 45px;
    top: 15px;
  }

  .item-selection-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
  }

  .item-selection-item {
    width: 80px;
    padding: 10px 10px 20px 10px;
  }

  .item-selection-name {
    font-size: 8px;
    width: 70px;
  }

  .equip-button {
    padding: 6px;
    font-size: 10px;
  }

  .selected-item {
    width: 40px;
    height: 40px;
  }

  .item-selection-item .status-overlay {
    position: absolute;
    top: 12px;
    left: -67px;
    width: 189%;
    height: 15px;
    line-height: 16px;
    text-align: center;
    transform: rotate(-45deg);
    font-size: 5px;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    z-index: 1;
    opacity: 1;
  }
}
