/* src/styles/footerGame.css */

.footer-game {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(145deg, #1a1a1a, #333);
    color: #fff;
    padding: 15px;
    border-radius: 10px;
    width: 790px;
    height: auto;
    border: 2px solid #F5130D;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

/* Gradient Background Overlay */
.footer-game::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Removendo o background com padrão */
    opacity: 0.15;
    pointer-events: none;
    z-index: 1;
}

/* Ensure elements inside .footer-game are above the ::before */
.footer-game-text,
.footer-content,
.footer-icons,
.footer-icon {
    position: relative;
    z-index: 2; /* Ensure these elements are above the gradient overlay */
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 15px;
}

/* Estilo para os ícones */
.footer-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.footer-icon {
    width: 40px;
    height: 40px;
    transition: transform 0.3s ease;
}

.footer-icon:hover {
    transform: scale(1.1);
}

.footer-icondiscord {
    width: 45px;
    height: 45px;
}

.footer-icondiscord:hover {
    transform: scale(1.15); 
}

.footer-game-text {
    font-size: 0.9em;
    margin: 10px 0 0;
    text-align: center;
}

/* Media query para tablets */
@media (max-width: 768px) {
    .footer-game {
        width: 580px;
        padding: 12px;
    }

    .footer-icons {
        gap: 15px;
    }

    .footer-icon {
        width: 35px;
        height: 35px;
    }

    .footer-icondiscord {
        width: 40px;
        height: 40px;
    }
}

/* Media query para celulares */
@media (max-width: 480px) {
    .footer-game {
        width: 320px;
        padding: 10px;
    }

    .footer-icons {
        gap: 10px;
    }

    .footer-icon {
        width: 30px;
        height: 30px;
    }

    .footer-icondiscord {
        width: 35px;
        height: 35px;
    }

    .footer-game-text {
        font-size: 0.7em;
        padding: 0 5px;
    }
}
