/* nftselector.css */

/* Custom Font */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

/* Overlay */
.nft-selector-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

/* Main Modal */
.nft-selector-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1a1a;
  border: 2px solid #F5130D;
  z-index: 1000;
  width: 80%;
  max-width: 900px;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 5px;
  color: white;
  font-family: 'Press Start 2P', sans-serif;
  box-shadow: 0 0 15px rgba(0,0,0,0.7);
  padding: 20px;
}

/* Modal Header */
.nft-selector-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #F5130D;
  margin-bottom: 20px;
}

.nft-selector-header h2 {
  color: #F5130D;
  font-size: 18px;
}

/* Close Button */
.nft-selector-close-button {
  background-color: #F5130D;
  color: white;
  border: 2px solid #c0392b; 
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 14px;
  position: absolute;
  top: 10px;
  right: 10px;
  box-shadow: 0 4px #7d240f; 
  transition: all 0.3s ease;
}

.nft-selector-close-button:hover {
  background-color: #ff6161;
  box-shadow: 0 6px #c0392b;
  transform: scale(1.05);
}

.nft-selector-close-button:active {
  box-shadow: 0 2px #d14727;
  transform: translateY(2px);
}

/* Filters */
.nft-selector-filters {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-bottom: 1px solid #444;
  margin-bottom: 20px;
}

.filter-section-nftselector {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}

.filter-section-nftselector h4 {
  margin-bottom: 5px;
  font-size: 14px;
  color: #F5130D;
}

.rarity-options {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.rarity-label-nftselector {
  margin-right: 150px;
  margin-bottom: 9px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 1 1 30%;
}

.rarity-label-nftselector input {
  margin-right: 5px;
}

.toggle-label {
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}

.toggle-label input {
  margin-right: 5px;
}

/* NFT Grid */
.nft-selector-grid-container {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  position: relative;
}

.nft-selector-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 39px 39px;
  justify-content: center;
  padding: 10px;
  width: 100%;
}

/* NFT Items */
.nft-selector-item {
  background-color: #1a1a1a;
  border: 2px solid #444;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 220px;
  width: 150px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.5);
  overflow: hidden;
}

.nft-selector-item:hover {
  transform: scale(1.08);
}

.nft-selector-item.inactive {
  opacity: 0.3;
}

.nft-selector-item .status-overlay {
  position: absolute;
  top: 19px;
  left: -108px;
  width: 170%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  transform: rotate(-45deg);
  font-size: 9px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  z-index: 1;
  opacity: 1;
}

.nft-selector-item .status-overlay.in-race {
  background-color: rgba(39,61,255,0.9);
  border: 2px solid #273dff;
  box-shadow: 0 0 8px rgba(39,61,255,0.5);
}

.nft-selector-item .status-overlay.in-stake {
  background-color: rgba(245,19,13,0.9);
  border: 2px solid #F5130D;
  box-shadow: 0 0 8px rgba(255,65,65,0.5);
}

.nft-selector-item.nft-selector-selected {
  background: #2f2f2f !important; /* Fundo mais escuro */
  border: 2px solid #F5130D; /* Borda vermelha para destacar */
  box-shadow: 0 0 10px #F5130D; /* Brilho vermelho ao redor */
  transform: scale(1.08) !important; /* Aumenta levemente o tamanho ao selecionar */
}

.nft-selector-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  border-radius: 6px;
  overflow: hidden;
}

.nft-selector-image {
  max-width: 130%;
  max-height: 100%;
  object-fit: contain;
}

.nft-selector-name {
  font-size: 8px;
  color: #fff;
  text-shadow: 0 0 2px #000;
  margin-top: 10px;
}

.nft-selector-id {
  font-size: 8px;
  color: #F5130D;
  margin-top: 5px;
}

.nft-selector-rarity {
  font-size: 9px;
  color: #F5130D;
  margin-top: 5px;
}

.nft-selector-refresh-button {
  background-color: #F5130D;
  color: white;
  border: 2px solid #c0392b; 
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  position: absolute;
  right: 70px;
  top: 15px;
  box-shadow: 0 4px #7d240f;
  transition: all 0.3s ease;
  font-family: 'Press Start 2P', sans-serif;
}

.nft-selector-refresh-button:hover {
  background-color: #ff6161;
  box-shadow: 0 6px #c0392b;
  transform: scale(1.05);
}

.nft-selector-refresh-button:active {
  box-shadow: 0 2px #d14727;
  transform: translateY(2px);
}

.nft-selector-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.nft-selector-spinner-wrapper {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-nfts-message {
  text-align: center;
  font-size: 18px;
  color: #555;
  margin-top: 20px;
}

.nft-selector-pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 5px;
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}

.nft-selector-pagination-button {
  background-color: #F5130D;
  color: white;
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.nft-selector-pagination-button:hover {
  background-color: #ff6161;
}

.nft-selector-pagination-button-active {
  background-color: #333;
  color: #F5130D;
}

.nft-selector-modal::-webkit-scrollbar {
  width: 16px; 
}

.nft-selector-modal::-webkit-scrollbar-track {
  background: #333; 
}

.nft-selector-modal::-webkit-scrollbar-thumb {
  background-color: #F5130D; 
  border: 2px solid #1a1a1a;
}

/* Selecionar Carros Button */
.select-cars-button {
  background-color: #F5130D;
  color: white;
  border: 2px solid #c0392b;
  border-radius: 10px;
  font-size: 12px;
  font-family: 'Press Start 2P', sans-serif;
  padding: 8px;
  cursor: pointer;
  margin-top: 28px;
  display: block;
  margin-bottom: 28px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 200px;
  text-transform: uppercase;
  box-shadow: 0 4px #7d240f;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1002;
}

.select-cars-button:hover {
  background-color: #ff6161;
  box-shadow: 0 6px #c0392b;
  transform: scale(1.05);
}

.select-cars-button:active {
  box-shadow: 0 2px #d14727;
  transform: translateY(2px);
}

/*ESTILOS DOS NOVOS NFTS SELECIOANDOS QUE JA ESTAO EM CARDISPLAY.JS*/
.already-selected {
  position: relative;
  opacity: 0.5;
  pointer-events: none; /* Desabilita a interação */
}

.already-selected-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: red;
  font-size: 0.8em;
}

/* Opcional: Adicione transições para uma melhor experiência do usuário */
.nft-selector-item {
  transition: opacity 0.3s ease;
}

@media (max-width: 768px) {
  .nft-selector-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 15px;
  }

  .nft-selector-item {
    padding: 10px;
    height: 180px;
    width: 120px;
  }

  .nft-selector-name {
    font-size: 10px;
  }

  .nft-selector-id,
  .nft-selector-rarity {
    font-size: 9px;
  }

  .nft-selector-refresh-button {
    font-size: 12px;
    padding: 8px 15px;
    top: 10px;
    right: 60px;
  }

  .nft-selector-close-button {
    font-size: 12px;
    padding: 8px;
  }

  .nft-selector-header h2 {
    font-size: 14px;
  }

  .nft-selector-item .status-overlay {
    top: 21px;
    left: -85px;
    font-size: 7px;
    height: 25px;
    line-height: 26px;
  }
}

@media (max-width: 480px) {
  .nft-selector-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;
  }

  .nft-selector-item {
    padding: 8px;
    height: 160px;
    width: 100px;
  }

  .nft-selector-name {
    font-size: 9px;
  }

  .nft-selector-id,
  .nft-selector-rarity {
    font-size: 8px;
  }

  .nft-selector-refresh-button {
    font-size: 10px;
    padding: 6px 10px;
    top: 8px;
    right: 38px;
  }

  .nft-selector-close-button {
    font-size: 10px;
    padding: 6px;
  }

  .nft-selector-header h2 {
    font-size: 10px;
  }

  .nft-selector-item .status-overlay {
    top: 18px;
    left: -68px;
    font-size: 7px;
  }
}

@media (max-width: 460px) {
  .nft-selector-grid {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 50px;
  }
}
