/* Fonte customizada */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    font-family: 'Press Start 2P', cursive;
    color: #fff;
    overflow-x: hidden; /* Remove a rolagem horizontal */
    background-color: #1a1a1a; /* Remover qualquer gradiente */
  }
  
  #root {
    height: 100%;
    width: 100%;
  }


/* Responsividade */
@media (max-width: 768px) {
    html, body {
        font-size: 14px; /* Ajusta o tamanho da fonte para telas menores */
    }

    .home-game {
        padding: 10px; /* Adiciona padding para garantir que o conteúdo não encoste nas bordas */
    }
}

@media (max-width: 480px) {
    html, body {
        font-size: 12px; /* Reduz o tamanho da fonte para telas muito pequenas */
    }

    .home-game {
        padding: 5px; /* Reduz o padding para caber melhor em telas pequenas */
    }
}

.modal {
    background-color: #1a1a1a;
    border: 2px solid #F5130D;
}
