/* Overlay */
.rewards-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Modal */
.rewards-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1a1a1a;
  padding: 20px;
  border: 2px solid #F5130D;
  z-index: 1000;
  width: 80%;
  max-width: 700px;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 5px;
  color: white;
  font-family: 'Press Start 2P', sans-serif;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s, opacity 0.3s;
}

.rewards-modal.show {
  display: block;
}

.close-button {
  background-color: #F5130D;
  color: white;
  border: 2px solid #c0392b;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 14px;
  position: absolute;
  top: 10px;
  right: 10px;
  box-shadow: 0 4px #7d240f;
  transition: all 0.3s ease;
}

.close-button:hover {
  background-color: #ff6161;
  box-shadow: 0 6px #c0392b;
  transform: scale(1.05);
}

.close-button:active {
  box-shadow: 0 2px #d14727;
  transform: translateY(2px);
}

.rewards-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #F5130D;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.rewards-header h2 {
  margin: 0;
  color: #F5130D;
  font-size: 24px;
  font-weight: 700;
}

.collect-all-button {
  background-color: #F5130D;
  color: white;
  border: 2px solid #c0392b;
  padding: 10px 20px;
  font-family: 'Press Start 2P', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 50px;
  border-radius: 10px;
  box-shadow: 0 4px #7d240f;
  transition: all 0.3s ease;
  margin-bottom: 5px;
}

.collect-all-button:hover {
  background-color: #ff6161;
  box-shadow: 0 6px #c0392b;
  transform: scale(1.05);
}

.collect-all-button:active {
  box-shadow: 0 2px #d14727;
  transform: translateY(2px);
}

/* Lista de recompensas */
.rewards-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.reward-columns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.reward-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reward-details-column {
  align-items: center;
  width: 50%;
}

.reward-nfts-column {
  width: 30%;
}

.reward-details-column .reward-car-name,
.reward-details-column {
  text-align: center;
  font-size: 11px;
  color: #F5130D;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1.2;
}

.reward-pxrs {
  text-align: center;
  font-size: 12px;
  color: #ffffff;
  margin-top: 2px;
}

.reward-car-image {
  width: 40px;
}

.reward-coins-rewardmodal {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1f1f1f;
  padding: 10px;
  border-radius: 5px;
  color: #ffd700;
  font-weight: bold;
  font-size: 15px;
  margin-top: 10px;
}

.reward-nft-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  justify-items: center;
}

.empty-nft-slot {
  width: 60px;
  height: 60px;
  border: 2px dashed #F5130D;
  border-radius: 5px;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #F5130D;
  font-size: 14px;
  font-family: 'Press Start 2P', sans-serif;
  text-transform: uppercase;
  transition: transform 0.3s, box-shadow 0.3s;
}

.empty-nft-slot:hover {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgba(255,65,65,0.7);
}

.reward-item {
  display: flex;
  flex-direction: column;
  background: #1c1c1c;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  transition: transform 0.3s, box-shadow 0.3s;
  border: 2px solid #F5130D;
  background-size: 20px 20px;
}

.reward-map,
.reward-ranking-season {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.reward-car-image {
  width: 150px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}

.reward-map-image {
  width: 120px;
  height: 70px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}

.rewardmodal-map-name {
  text-align: center;
  font-size: 10px;
  color: #F5130D;
  margin-top: 5px;
  line-height: 1.2;
}

.reward-ranking-season {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  font-variant-caps: all-small-caps;
  color: #1f1f1f;
  margin-top: 0px;
  margin: 10px 10px 0px;
}

.reward-details {
  text-align: left;
  flex: 1;
}

.reward-car-name {
  font-size: 18px;
  margin-bottom: 5px;
  color: #F5130D;
}

.reward-position {
  font-size: 12px;
  color: #ffffff;
  margin-top: 5px;
  text-align: center;
}

.season-trophy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.season-trophy-image {
  width: 60px;
  margin-bottom: 5px;
}

.ranking-reward {
  background: #ffd700;
  border-color: #ffd700;
  background-image: linear-gradient(45deg, rgba(255,42,42,0.1) 25%, transparent 25%), 
                    linear-gradient(-45deg, rgba(255,42,42,0.1) 25%, transparent 25%), 
                    linear-gradient(45deg, transparent 75%, rgba(255,42,42,0.1) 75%), 
                    linear-gradient(-45deg, transparent 75%, rgba(255,42,42,0.1) 75%);
  background-size: 50px 50px;
}

.reward-ranking {
  color: #1f1f1f;
}

/* NFTs */
.reward-nft-slot {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  border: 2px solid #F5130D;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}

.reward-ranking-nft-slot {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  border: 2px solid #1f1f1f;
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}

.reward-nft-slot:hover {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgba(255,65,65,0.7);
}

.reward-ranking-nft-slot:hover {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgba(255,253,164,0.75);
}

.reward-nft-image,
.reward-ranking-nft-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #1f1f1f;
}

.reward-nft-amount,
.reward-ranking-nft-amount {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.6);
  color: #fff;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}

.reward-log-toggle {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.toggle-button {
  background: none;
  border: none;
  color: #ffd700;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-family: 'Press Start 2P', sans-serif;
}

.toggle-button:hover {
  color: #ff6161;
}

.reward-events-log {
  background: linear-gradient(145deg, #2c2c2c, #1f1f1f);
  border: 1px solid #F5130D;
  margin-top: 10px;
  border-radius: 5px;
  overflow-y: auto;
  max-height: 300px;
  padding: 10px;
}

/* Títulos das seções */
.log-section-title {
  font-size: 16px;
  color: #F5130D;
  text-transform: uppercase;
  border-bottom: 2px solid #F5130D;
  padding-bottom: 5px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
}

/* Pre-Race e Post-Race listas simples */
.simple-list-section {
  margin-bottom: 20px;
}

.simple-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.simple-list-item {
  padding: 5px 0;
  border-bottom: 1px dashed #555;
  font-size: 12px;
  line-height: 1.4;
}

.simple-list-item:last-child {
  border-bottom: none;
}

/* Race Events tabela */
.events-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.events-table th {
  color: #F5130D;
  border-bottom: 2px solid #F5130D;
  padding: 8px;
  text-align: center;
  font-size: 12px;
}

.events-table td {
  /*text-align: center;*/
  padding: 8px;
  font-size: 12px;
  color: #ffffff;
  border-bottom: 1px dashed #555;
}

.events-table tr:last-child td {
  border-bottom: none;
}

.event-text-col {
  text-align: left;
  width: 80%;
}

.event-pts-col {
  text-align: right;
  width: 20%;
}

.event-header-row td.event-header {
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  color: #ffd700;
  border-bottom: 1px solid #F5130D;
}

/* Final Standings */
.final-standings-section {
  border: 1px solid #F5130D;
  background: #1f1f1f;
  border-radius: 5px;
  padding: 10px;
}

.standings-table {
  width: 100%;
  border-collapse: collapse;
}

.standings-table th {
  color: #F5130D;
  border-bottom: 2px solid #F5130D;
  padding: 8px;
  text-align: center;
  font-size: 12px;
}

.standings-table td {
  text-align: center;
  padding: 8px;
  font-size: 12px;
  color: #ffffff;
  border-bottom: 1px dashed #555;
}

.standings-table tr:last-child td {
  border-bottom: none;
}

/* Paginação */
.rewards-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-top: 30px; /* Espaçamento superior para afastar das recompensas */
}

.rewards-pagination button {
  background-color: #F5130D;
  color: white;
  border: 2px solid #c0392b;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 10px;
  font-family: 'Press Start 2P', sans-serif;
  font-size: 12px;
  transition: all 0.3s ease;
}

.rewards-pagination button:hover:not(:disabled) {
  background-color: #ff6161;
  box-shadow: 0 6px #c0392b;
  transform: scale(1.05);
}

.rewards-pagination button:active:not(:disabled) {
  box-shadow: 0 2px #d14727;
  transform: translateY(2px);
}

.rewards-pagination button:disabled {
  background-color: #555;
  border-color: #777;
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
}

.rewards-pagination span {
  color: #F5130D;
  font-family: 'Press Start 2P', sans-serif;
  font-size: 14px;
}

.rewards-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5); /* Opcional, para dar um fundo escuro enquanto carrega */
  z-index: 999;
}

/* Container da Recompensa de Ranking */
.ranking-reward {
  display: flex;
  flex-direction: column;
  /*background: linear-gradient(145deg, #2e2e2e, #444);*/
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.5); /* Sombra dourada */
  border: 3px solid #ffd700; /* Borda dourada */
  transition: transform 0.3s, box-shadow 0.3s;
}

.ranking-reward:hover {
  transform: scale(1.02);
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.8);
}

/* Container do Troféu da Temporada */
.ranking-reward .season-trophy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.ranking-reward .season-trophy-image {
  width: 60px;
  margin-bottom: 5px;
}

/* Nome da Temporada */
.ranking-reward .reward-ranking-season {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  font-variant-caps: all-small-caps;
  color: #ffd700; /* Cor dourada */
  margin-top: 10px;
}

/* Detalhes da Recompensa de Ranking */
.ranking-reward .reward-ranking {
  color: #1f1f1f;
  font-size: 16px;
  margin-bottom: 10px;
}

/* Grid de NFTs na Recompensa de Ranking com Espaçamento */
.ranking-reward .reward-ranking-nft-grid {
  /*display: grid;*/
  grid-template-columns: repeat(3, 1fr);
  gap: 10px; /* Espaçamento entre os slots */
  justify-items: center;
}

/* Slot de NFT de Ranking */
.ranking-reward .reward-ranking-nft-slot {
  position: relative;
  width: 60px;
  height: 60px;
  border: 2px solid #ffd700; /* Borda dourada */
  border-radius: 5px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}

.ranking-reward .reward-ranking-nft-slot:hover {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgba(255, 253, 164, 0.75); /* Sombra dourada */
}

/* Imagem de NFT de Ranking */
.ranking-reward .reward-ranking-nft-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /*background: #ffd700; /* Fundo dourado */
}

/* Quantidade de NFT de Ranking */
.ranking-reward .reward-ranking-nft-amount {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}

/* Slot vazio de NFT de Ranking */
.ranking-reward .empty-ranking-nft-slot {
  width: 60px;
  height: 60px;
  border: 2px dashed #ffd700;
  border-radius: 5px;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffd700;
  font-size: 14px;
  text-transform: uppercase;
  transition: transform 0.3s, box-shadow 0.3s;
}

.ranking-reward .empty-ranking-nft-slot:hover {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgba(255, 253, 164, 0.7);
}


/* Barra de rolagem personalizada para .rewards-list */
.rewards-modal.show::-webkit-scrollbar {
  width: 16px; /* Ajusta a largura da barra de rolagem */
}

.rewards-modal.show::-webkit-scrollbar-track {
  background: #333; /* Cor do fundo da barra de rolagem */
}

.rewards-modal.show::-webkit-scrollbar-thumb {
  background-color: #F5130D; /* Cor da barra de rolagem */
  border-radius: 0px; /* Arredondamento da barra */
  border: 2px solid #1a1a1a; /* Borda da barra de rolagem */
}

/* Barra de rolagem personalizada para .reward-events-log (Race Logs) */
.reward-events-log::-webkit-scrollbar {
  width: 16px; /* Ajusta a largura da barra de rolagem */
}

.reward-events-log::-webkit-scrollbar-track {
  background: #333; /* Cor do fundo da barra de rolagem */
}

.reward-events-log::-webkit-scrollbar-thumb {
  background-color: #F5130D; /* Cor da barra de rolagem */
  border-radius: 0px; /* Arredondamento da barra */
  border: 2px solid #1a1a1a; /* Borda da barra de rolagem */
}

/* Responsividade */
@media (max-width: 768px) {
  .rewards-modal {
    width: 90%;
    padding: 15px;
  }

  .reward-columns {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .reward-map-image,
  .reward-car-image {
    width: 120px;
    height: 80px;
  }

  .reward-nft-slot,
  .reward-ranking-nft-slot {
    width: 50px;
    height: 50px;
  }

  .reward-nft-amount {
    font-size: 10px;
  }

  .collect-all-button {
    padding: 8px 16px;
    font-size: 12px;
    margin-right: 70px;
  }

  .reward-ranking-season {
    margin-bottom: 10px;
    margin-right: 0px;
  }

  .standings-table th, .standings-table td,
  .events-table th, .events-table td,
  .simple-list-item {
    font-size: 10px;
    padding: 5px;
  }

  .event-text, .event-pts {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .rewards-modal {
    padding: 10px;
  }

  .reward-item {
    /*width: 100%;*/
  }

  .reward-map-image,
  .reward-car-image {
    width: 100px;
    height: 66px;
  }

  .reward-nft-slot,
  .reward-ranking-nft-slot {
    width: 60px;
    height: 60px;
  }

  .reward-nft-amount {
    font-size: 8px;
  }

  .collect-all-button {
    width: 100px;
    height: 40px;
    padding: 6px 12px;
    font-size: 10px;
    margin-right: 60px;
    line-height: 1.2;
  }

  .rewards-header h2 {
    font-size: 18px;
  }

  .reward-events-log {
    max-height: 200px;
  }

  .event-text, .event-pts {
    font-size: 10px;
  }

  .standings-table th, .standings-table td,
  .events-table th, .events-table td,
  .simple-list-item {
    font-size: 8px;
  }

  .log-section-title {
    font-size: 14px;
  }
}

.rewards-total {
  background: rgba(245, 19, 13, 0.1);
  border: 1px solid rgba(245, 19, 13, 0.3);
  border-radius: 8px;
  padding: 5px 10px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: absolute;
  left: 46%;
  transform: translateX(-50%);
}

.rewards-total-label {
  font-size: 0.6em;
  color: #fff;
}

.rewards-total-amount {
  font-size: 0.7em;
  font-weight: bold;
  color: #F5130D;
}
