/* Fonte customizada */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.attributes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    background: linear-gradient(145deg, #1a1a1a, #333);
    border: 2px solid #F5130D;
    border-radius: 10px;
    color: white;
    margin-top: 20px;
    font-family: 'Press Start 2P', sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    width: 790px;
    position: relative;
    z-index: 10;
    opacity: 1 !important;
    visibility: visible !important;
    max-height: none !important;
    transition: none !important;
}

.attributes-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px;
    opacity: 1 !important;
    visibility: visible !important;
}

.attribute {
    width: 90%;
    margin: 8px auto;
    padding: 8px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
    font-size: 0.9em;
    text-align: center;
    color: #F5130D;
    opacity: 1 !important;
    visibility: visible !important;
}

@media (max-width: 768px) {
    .attributes {
        width: 580px;
    }

    .attribute {
        font-size: 0.8em;
    }
}

@media (max-width: 480px) {
    .attributes {
        width: 277px;
    }

    .attribute {
        font-size: 0.7em;
        padding: 6px;
    }
}

/* Gradient Background Overlay */
.attributes::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Removendo o background com padrão */
    opacity: 0.15;
    pointer-events: none;
    z-index: 1;
}

/* Ensure elements inside .attributes are above the ::before */
.attributes-content,
.attribute,
.toggle-attributes-button {
    position: relative;
    z-index: 2; /* Ensure these elements are above the gradient overlay */
}

/* Remover todos os estilos relacionados ao toggle button */
.toggle-attributes-button {
    display: none; /* Temporariamente, até removermos completamente */
}

/* Remover media queries relacionadas ao toggle button */
@media (max-width: 1024px) {
    .toggle-attributes-button {
        display: none;
    }
}
